import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEqual } from 'lodash';
import { ROUTES } from '../../../constants';
import Header from '../../../components/Headers/MaillingHeader';
import MailingBlock from '../../../components/mailingBlock/MailingBlock';
import StudentsList from '../../../components/StudentsList/StudentsList';
import { authorisationHoc } from '../../../hoc/authorusationHoc';
import { getProfile } from '../../../actions/authorisation';
import { studentsSelector } from '../../../selectors';
import { fetchCourse, fetchFaculty, fetchFunctionalGroups } from '../../../actions';
import s from './mailing.module.scss';

class Mailing extends React.Component {
  state = {
    checkedStudents: [],
    allChecked: true
  };

  componentDidMount() {
    if (this.props.user) {
      this.props.fetchCourse();
      this.props.fetchFaculty();
      this.props.fetchFunctionalGroups();
    } else {
      const key = this.props.location.pathname.split('/')[1];
      this.props.getProfile({
        token: localStorage.getItem(ROUTES[key]),
        pathname: key,
        redirect: this.props.location.pathname
      });
    }
  }

  componentWillReceiveProps({ students, user }) {
    if (!isEqual(students, this.props.students)) {
      if (this.state.allChecked) {
        this.setState({ checkedStudents: students.map((s) => s.id) });
      } else {
        this.setState({ checkedStudents: [] });
      }
    }

    if (!isEqual(user, this.props.user) && user) {
      this.props.fetchCourse();
      this.props.fetchFaculty();
      this.props.fetchFunctionalGroups();
    }
  }

  changeAllCheck = (value) => {
    return value ? this.props.students.map((student) => student.id) : [];
  };

  handleAllCheck = (value) =>
    this.setState({
      allChecked: value,
      checkedStudents: this.changeAllCheck(value)
    });

  handleCheckStundent = (value, id) => {
    if (value) {
      this.setState((state) => ({
        checkedStudents: state.checkedStudents.concat(id),
        allChecked:
          state.checkedStudents.concat(id).length === this.props.students.length
      }));
    } else {
      this.setState((state) => ({
        checkedStudents: state.checkedStudents.filter((i) => i !== id),
        allChecked: false
      }));
    }
  };

  render() {
    return (
      <div className={s.wrapper}>
        <div className={s.content}>
          <Header />
          <div className={s.contentBlocks}>
            <MailingBlock
              allChecked={this.state.allChecked}
              checkedStudents={this.state.checkedStudents}
            />
            <StudentsList
              allChecked={this.state.allChecked}
              handleAllCheck={this.handleAllCheck}
              checkedStudents={this.state.checkedStudents}
              handleCheckStundent={this.handleCheckStundent}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.profile,
    students: studentsSelector(state)
  };
};

const mapDispatchToProps = {
  getProfile,
  fetchCourse,
  fetchFaculty,
  fetchFunctionalGroups
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(authorisationHoc(Mailing));
