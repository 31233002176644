import DocumentIcon from '../images/doc-file.svg';

export const ROUTES = {
  mailing: 'employee',
  messages: 'student',
  'mail-history': 'employee',
  'rector-mail-history': 'employee',
  groups: 'employee',
  schedule: 'student',
  payments: 'student'
}

export const TYPES = {
  'vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'msword': 'doc',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'vnd.ms-powerpoint': 'ppt',
  'vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx'
}

export const ICONS = {
  doc: DocumentIcon,
  docx: DocumentIcon,
  pdf: 'https://img.icons8.com/metro/52/000000/pdf-2.png',
  jpeg: 'https://img.icons8.com/metro/52/000000/jpg.png',
  jpg: 'https://img.icons8.com/metro/52/000000/jpg.png',
  png: 'https://img.icons8.com/metro/52/000000/png.png',
  xls: 'https://img.icons8.com/material-rounded/48/000000/xls.png',
  xlsx: 'https://img.icons8.com/material-rounded/48/000000/xls.png',
  ppt: 'https://img.icons8.com/material-rounded/48/000000/ppt.png',
  pptx: 'https://img.icons8.com/material-rounded/48/000000/ppt.png'
}

export const ERRORS = {
  title: 'Заголовок сообщения превышает допустимое число символов равное 50.',
  message: 'Вы превысили максимально допустимое число символов равное 1000.',
  'error.file-upload.wrong-type': 'Недопустимый формат файла.',
  'error.file-upload.size-limit': 'Размер файла превышает максимально допустимое значение равное 10 мб.'
}
