import { handleActions } from "redux-actions";
import { isEmpty } from 'lodash';
import { initialState } from "../../store/mail";
import { setMessages, isCleanRequared, cleanMessages, setCurrentMessage, getUnreadMessages } from "../../actions/mail";
import { sendMessage, sendMessageSuccess, sendMessageFailed } from '../../actions';

const mailReducer = handleActions({

  [setMessages.toString()]: (state, { payload: { messages, next } }) => {
    const messagesTotal = state.messages.concat(messages.items)
    return {
      ...state,
      messages: next || isEmpty(state.messages) ? messagesTotal : state.messages,
      currentPage: next || isEmpty(state.messages) ? state.currentPage + 1 : state.currentPage,
      hasMore: messagesTotal.length < Number(messages.pager.total_count),
    };
  },
  [getUnreadMessages.toString()]: (state, { payload: unread }) => {
    return {
      ...state,
      unread
    };
  },

  [isCleanRequared.toString()]: (state, { payload: bool }) => {
    return {
      ...state,
      isCleanRequared: bool,
    };
  },

  [cleanMessages.toString()]: (state, { payload }) => {
    return {
      ...state,
      messages: [],
      currentPage: 1,
    };
  },

  [setCurrentMessage.toString()]: (state, { payload: message }) => {
    return {
      ...state,
      currentMessage: message,
    };
  },
  [sendMessage.toString()]: (state) => {
    return {
      ...state,
      isSending: true,
    };
  },
  [sendMessageSuccess.toString()]: (state) => {
    return {
      ...state,
      isSending: false,
    };
  },
  [sendMessageFailed.toString()]: (state) => {
    return {
      ...state,
      isSending: false,
    };
  },

}, initialState);

export default mailReducer;
