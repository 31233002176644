import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { isEqual, get, isEmpty } from "lodash";
import { Select } from "antd";

import { ROUTES } from "../../../constants";
import {
  fetchFaculty,
  clearFaculty,
  fetchEmployee,
  clearEmployee
} from "../../../actions";
import Header from "../../../components/Headers/MaillingHeader";
import MessagesTable from "../../../components/MessagesTable/MessagesTable";
import {
  messagesSelector,
  hasMoreSelector
} from "../../../selectors/mailSelectors";
import { facultySelector, employeeSelector } from "../../../selectors";
import { getMessages, cleanMessages } from "../../../actions/mail";
import { getProfile } from "../../../actions/authorisation";
import { authorisationHoc } from "../../../hoc/authorusationHoc";
import s from "./historyMail.module.scss";

class HistoryMail extends React.Component {
  state = {
    faculty: undefined,
    employee: undefined,
    checkbox: false
  };

  componentWillUnmount() {
    this.props.clearFaculty();
    this.props.clearEmployee();
    this.props.cleanMessages();
  }

  componentDidMount() {
    if (this.props.user) {
      this.props.cleanMessages();
      this.props.clearFaculty();
      !this.props.user.is_rector && this.props.getMessages({ next: false });
      this.props.fetchFaculty();
    } else {
      const key = this.props.location.pathname.split("/")[1];
      this.props.getProfile({
        token: localStorage.getItem(ROUTES[key]),
        pathname: key,
        redirect: this.props.location.pathname
      });
    }
  }

  componentWillReceiveProps({ user, faculty, employee }) {
    if (!isEqual(user, this.props.user) && user) {
      this.props.cleanMessages();
      !user.is_rector && this.props.getMessages({ next: false });
      this.props.fetchFaculty();
    }

    if (!isEqual(faculty, this.props.faculty) && !isEmpty(faculty) && user) {
      this.props.cleanMessages();
      this.props.getMessages({ next: false, faculty: faculty[0].id });
      this.props.fetchEmployee({ faculty: faculty[0].id });
      this.setState({ faculty: faculty[0].id });
    }
  }

  onChange = faculty => {
    this.props.cleanMessages();
    this.props.getMessages({ next: false, faculty });
    this.props.fetchEmployee({ faculty: faculty });
    this.setState({ faculty, employee: undefined });
  };

  onChangeEmployee = employee => {
    this.props.cleanMessages();
    this.props.getMessages({
      next: false,
      employee_id: employee,
      faculty: this.state.faculty
    });
    this.setState({ employee, checkbox: false });
  };

  onChangeChbox = () => {
    this.props.cleanMessages();
    this.setState((state) => ({ checkbox: !state.checkbox, employee: undefined }), () => {
      if (this.state.checkbox) {
        this.props.getMessages({
          next: false,
          employee_id: this.props.user.id,
          faculty: this.state.faculty
        });
      } else {
        this.props.getMessages({
          next: false,
          faculty: this.state.faculty
        });
      }
    });
  };

  render() {
    const { user, faculty, employee } = this.props;
    const employee_id = this.state.checkbox ? user.id : this.state.employee;
    const { Option } = Select;
    const displayOptions = faculty.map(o => (
      <Option key={o} value={o.id}>
        {o.name}
      </Option>
    ));
    const displayOptionsEmployee = employee.map(d => (
      <Option key={d.id} value={d.id}>
        {d.full_name}
      </Option>
    ));

    return (
      <div className={s.wrapper}>
        <div className={s.content}>
          {user && <Header rector={get(user, "is_rector")} />}
          <div className={s.subTitle}>{get(user, "faculty.name")}</div>
          <div className={s.title}>История рассылок</div>
          <div>
            {(get(user, "is_rector") || get(user, "is_admin")) && (
              <div className={s.select}>
                <Select
                  className={s.select}
                  placeholder="Выберите факультет"
                  optionFilterProp="children"
                  onChange={this.onChange}
                  value={this.state.faculty}
                >
                  {displayOptions}
                </Select>
              </div>
            )}

            {!get(user, "is_admin") && (
              <div className={s.select}>
                <Select
                  className={s.select}
                  placeholder="Выберите сотрудника факультета"
                  optionFilterProp="children"
                  value={this.state.employee}
                  onChange={this.onChangeEmployee}
                >
                  {displayOptionsEmployee}
                </Select>
              </div>
            )}

            {(!get(user, "is_rector") && !get(user, "is_admin")) && (
              <div className={s.checkbox}>
                <div className={s.checkboxWrapper}>
                  <input type="checkbox" onClick={this.onChangeChbox} checked={this.state.checkbox} />
                  <span className={s.text}>Только мои рассылки</span>
                </div>
              </div>
            )}
          </div>

          <MessagesTable
            faculty={this.state.faculty}
            employee_id={employee_id}
            messages={this.props.messages}
            hasMore={this.props.hasMore}
            getMessages={this.props.getMessages}
            is_admin={get(user, "is_admin")}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.profile,
    messages: messagesSelector(state),
    hasMore: hasMoreSelector(state),
    faculty: facultySelector(state),
    employee: employeeSelector(state)
  };
};

const mapDispatchToProps = {
  getMessages,
  getProfile,
  fetchFaculty,
  cleanMessages,
  clearFaculty,
  fetchEmployee,
  clearEmployee
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(authorisationHoc(HistoryMail));
