import React from "react";
import { injectIntl } from "react-intl";
import { Button, Input, Form, Radio } from "antd";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import Checkbox from "../../Checkbox/Checkbox";

import styles from "./Feedback.module.scss";

class Feedback extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };

  render() {
    const { intl, form, isLoading, onlyFeedback, location } = this.props;
    const { getFieldDecorator } = form;
    const modifyOnlyFeedback = !onlyFeedback || location.pathname === '/login/student';

    const supportServiceLabel = intl.formatMessage({ id: "supportService" });
    const inSupportServiceLabel = intl.formatMessage({
      id: "inSupportService",
    });
    const inDeaneryLabel = intl.formatMessage({
      id: "inDeanery",
    });
    const phoneOrEmailLabel = intl.formatMessage({ id: "phoneOrEmail" });
    const headTextLabel = intl.formatMessage({ id: "headingText" });
    const messageTextLabel = intl.formatMessage({ id: "messageText" });
    const sendMessagePolicyLabel = intl.formatMessage({
      id: "sendMessagePolicy",
    });
    const securePolicyLabel = intl.formatMessage({ id: "securePolicy" });
    const sendLabel = intl.formatMessage({ id: "send" });

    const requiredLabel = intl.formatMessage({ id: "error.required" });
    const maxLength1000Label = intl.formatMessage({
      id: "error.maxLength1000",
    });
    const maxLength50Label = intl.formatMessage({ id: "error.maxLength50" });
    const minLength2Label = intl.formatMessage({ id: "error.minLength2" });
    const activeLabel = intl.formatMessage({ id: "error.active" });

    return (
      <Form name="basic" onSubmit={this.handleSubmit}>
        <div className={styles.title}>{supportServiceLabel}</div>

        <Form.Item hidden={modifyOnlyFeedback}>
          {getFieldDecorator("target", {
            initialValue: "support",
          })(
            <Radio.Group className={styles.target} disabled={isLoading}>
              <Radio value="feedback">{inDeaneryLabel}</Radio>
              <Radio value="support">{inSupportServiceLabel}</Radio>
            </Radio.Group>
          )}
        </Form.Item>
        {/* // */}
        {/* // */}
        {/* // */}
        {form.getFieldsValue().target === "support" && (
          <Form.Item>
            {getFieldDecorator("phoneOrEmail", {
              rules: [
                { required: true, message: requiredLabel },
                { max: 50, message: maxLength50Label },
                { min: 2, message: minLength2Label },
              ],
            })(
              <Input
                placeholder={phoneOrEmailLabel}
                disabled={isLoading}
                size="large"
              />
            )}
          </Form.Item>
        )}
        {/* // */}
        {/* // */}
        {/* // */}
        <Form.Item>
          {getFieldDecorator("headText", {
            rules: [
              { required: true, message: requiredLabel },
              { max: 50, message: maxLength50Label },
              { min: 2, message: minLength2Label },
            ],
          })(
            <Input
              placeholder={headTextLabel}
              size="large"
              disabled={isLoading}
            />
          )}
        </Form.Item>
        {/* // */}
        {/* // */}
        {/* // */}
        <Form.Item>
          {getFieldDecorator("messageText", {
            rules: [
              { required: true, message: requiredLabel },
              { max: 1000, message: maxLength1000Label },
            ],
          })(
            <Input.TextArea
              placeholder={messageTextLabel}
              size="large"
              rows={6}
              disabled={isLoading}
            />
          )}
        </Form.Item>
        {/* // */}
        {/* // */}
        {/* // */}
        <Form.Item>
          {getFieldDecorator("securePolicy", {
            rules: [
              { required: true, message: requiredLabel },
              {
                validator: (rule, value) => value === true,
                message: activeLabel,
              },
            ],
          })(
            <Checkbox disabled={isLoading}>
              <span>
                {sendMessagePolicyLabel}{" "}
                <a
                  className={styles.link}
                  href="http://lk.bsmu.by/bsmuapplicationprivacy.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {securePolicyLabel}
                </a>
              </span>
            </Checkbox>
          )}
        </Form.Item>
        {/* // */}
        {/* // */}
        {/* // */}
        <div className={styles.actionsWrapper}>
          <Button
            type="primary"
            shape="round"
            size="large"
            htmlType="submit"
            loading={isLoading}
          >
            {sendLabel}
          </Button>
        </div>
      </Form>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  Form.create({ name: "normal_login" })
)(Feedback);
