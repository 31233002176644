import { notification } from "antd";
import { get } from "lodash";
import { put, delay } from "redux-saga/effects";
import {
  sendFeedbackSuccess,
  sendFeedbackFailure,
  toggleModal,
} from "../actions/feedbackModal";
import axios from "../api";

export function* sendFeedbackSaga(action) {
  try {
    const values = action.payload.values;

    const params = {
      title: values.headText,
      message: values.messageText,
      type: values.target,
      data_track: values.securePolicy
    };

    if (values.target === "support") {
      params.contact = values.phoneOrEmail;
    }

    // Just to make request smoother
    yield delay(300);
    const response = yield axios.post("/api/feedback", params);

    if (response.status !== 200) {
      throw response;
    }

    yield put(sendFeedbackSuccess());

    const message = get(action, "payload.constants.success");
    if (message) {
      notification.success({ message });
    }

    yield put(toggleModal());
  } catch (error) {
    console.log(error);

    yield put(sendFeedbackFailure());

    const message = get(action, "payload.constants.error");
    if (message) {
      notification.error({ message });
    }
    yield put(toggleModal());
  }
}
