import { createSelector } from "reselect";

const rootSelector = state => state.root;

export const coursesSelector = createSelector(rootSelector, root => root.courses || []);
export const studentsSelector = createSelector(rootSelector, root => root.students || []);
export const studentsIsLoading = createSelector(rootSelector, root => root.studentsIsLoading);
export const groupsSelector = createSelector(rootSelector, root => root.groups || []);
export const facultySelector = createSelector(rootSelector, root => root.faculty || []);
export const employeeSelector = createSelector(rootSelector, root => root.employee || []);
export const functionalGroupsSelector = createSelector(rootSelector, root => root.functionalGroups.items || []);
export const scheduleSelector = createSelector(rootSelector, root => root.schedule || []);
