import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Icon } from 'antd';
import { get, isEmpty, isEqual, last } from 'lodash';
import { NavLink } from 'react-router-dom';
import { connect } from "react-redux";
import Moment from 'moment';
import { FormattedMessage } from "react-intl";
import { compose } from "redux";
import history from '../../history';

import { ROUTES, ICONS } from '../../constants';
import { getCurrentMessage, updateMessage } from '../../actions/mail';
import { uploadFile } from '../../actions';
import { getProfile } from '../../actions/authorisation';
import { currentMessageSelector, actionMessageSelector, messagesSelector } from '../../selectors/mailSelectors';
import s from './message.module.scss';
import './message.scss';

class Message extends React.Component {
  componentDidMount() {
    if (this.props.user) {
			this.props.getCurrentMessage(this.props.match.params.id);
      this.props.updateMessage(this.props.match.params.id);
		} else {
			const key = this.props.location.pathname.split('/')[1];
      if (!localStorage.getItem(ROUTES[key])) {
        (this.props.location.pathname === '/rector-mail-history' || this.props.location.pathname === '/mail-history' || this.props.location.pathname === '/mailing') && history.push('/login/employee');
        this.props.location.pathname === '/messages' && history.push('/login/student');
      } else {
				this.props.getProfile({ token: localStorage.getItem(ROUTES[key]), pathname: key, redirect: this.props.location.pathname });
			}
		}
  }

  componentWillReceiveProps({ user, match }) {
		if (!isEqual(user, this.props.user) && user) {
			this.props.getCurrentMessage(this.props.match.params.id);
      this.props.updateMessage(this.props.match.params.id);
    }
    if (!isEqual(match.params.id && this.props.match.params.id)) {
      this.props.updateMessage(match.params.id);
    }
	}

  render () {
    const { currentMessage, action, uploadFile } = this.props;
    return (
      <div className={s.wrapper}>
        <div className={s.content}>
          <div className={s.link}><NavLink to="/messages"><Icon type="left" style={{ color: '#1890ff' }} /><FormattedMessage id="back" /></NavLink></div>
          <div className={s.title}>
            { get(currentMessage,'title', '') }
          </div>
          <div className={s.date}>
          { Moment(get(currentMessage,'created_at', '')).format('DD MMMM YYYY') }
            </div>
          <div className={s.message}>
          { get(currentMessage, 'message', '') }
          </div>
          {!isEmpty(get(currentMessage, 'files', [])) && <div className={s.fileWrapper}>
            <div className={s.file} onClick={() => uploadFile(get(currentMessage, 'files')[0].id)}>
              <img className={s.document} alt='doc' src={ICONS[last(get(currentMessage, 'files')[0].file_name.split('.'))]} />
              <span>{get(currentMessage, 'files')[0].file_name}</span>
            </div>
            <span>
              <Icon type="close" style={{ color: 'gray' }} />
            </span>
          </div>}
          <div className={`${s.buttonsContainer} ${'messageButtonsContainer'}`}>
            <div>
              {get(action, 'prev') && <Button onClick={() => {
                this.props.getCurrentMessage(get(action, 'prev'));
                this.props.currentMessage && history.push(`/messages/${get(action, 'prev')}`)
                }} type="primary" shape="round" size='large' style={{ width: '175px' }}>
                <Icon type="arrow-left" />
                <FormattedMessage id="previousMessage" />
              </Button>}
            </div>
            <div>
              {get(action, 'next') && <Button onClick={() => {
                this.props.getCurrentMessage(get(action, 'next'))
                this.props.currentMessage && history.push(`/messages/${get(action, 'next')}`)
                }} type="primary" shape="round" size='large' style={{ width: '175px' }}>
              <FormattedMessage id="nextMessage" />
                <Icon type="arrow-right" />
              </Button>}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    user: state.user.profile,
    currentMessage: currentMessageSelector(state),
    action: actionMessageSelector(state),
    messages: messagesSelector(state),
  }
};

const mapDispatchToProps = {
  getCurrentMessage,
  updateMessage,
  uploadFile,
  getProfile
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Message);
