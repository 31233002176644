import { handleActions } from "redux-actions";
import {
  fetchStudentsFaled,
  setCourse,
  setStudents,
  setGroups,
  setFaculty,
  clearFaculty,
  clearStudents,
  fetchStudents,
  createGroupStart,
  createGroupSuccess,
  fetchFunctionalGroups,
  setFunctionalGroups,
  clearEmployee,
  setEmployee,
  setSchedule
} from "../actions";

const initialState = {
  courses: [],
  students: [],
  groups: [],
  faculty: [],
  employee: [],
  functionalGroups: {
    items: [],
    isFetching: false,
    isCreating: false,
    error: null
  },
  schedule: [],
  studentsIsLoading: false,
  localisationMode: true
};

const main = handleActions({
  [fetchFunctionalGroups.toString()]: (state) => {
    return {
      ...state,
      functionalGroups: {
        ...state.functionalGroups,
        isFetching: true,
        items: []
      }
    };
  },
  [setFunctionalGroups.toString()]: (state, { payload }) => {
    return {
      ...state,
      functionalGroups: {
        ...state.functionalGroups,
        isFetching: false,
        items: payload
      }
    };
  },
  [createGroupStart.toString()]: (state) => {
    return {
      ...state,
      functionalGroups: {
        ...state.functionalGroups,
        isCreating: true
      }
    };
  },
  [createGroupSuccess.toString()]: (state) => {
    return {
      ...state,
      functionalGroups: {
        ...state.functionalGroups,
        isCreating: false
      }
    };
  },
  [clearFaculty.toString()]: (state) => {
    return {...state, faculty: []};
  },
  [clearEmployee.toString()]: (state) => {
    return {...state, employee: []};
  },
  [clearStudents.toString()]: (state) => {
    return {...state, students: [], groups: []};
  },

  [setFaculty.toString()]: (state, { payload: { faculty } }) => {
    return {...state, faculty };
  },
  [setEmployee.toString()]: (state, { payload: { employee } }) => {
    return {...state, employee};
  },
  [setCourse.toString()]: (state, { payload: { courses } }) => {
    return {...state, courses};
  },

  [fetchStudents.toString()]: (state) => {
    return {...state, studentsIsLoading: true};
  },

  [fetchStudentsFaled.toString()]: (state) => {
    return {...state, studentsIsLoading: false};
  },

  [setStudents.toString()]: (state, { payload: { students } }) => {
    return {...state, students, studentsIsLoading: false};
  },

  [setGroups.toString()]: (state, { payload: { groups } }) => {
    return {...state, groups};
  },


  [setSchedule.toString()]: (state, {  payload: { schedule } }) => {
    return {...state, schedule};
  }

}, initialState);

export default main;
