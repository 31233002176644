import {
  put
} from 'redux-saga/effects';
import download from 'downloadjs';

import { TYPES } from '../constants';
import {
  setCourse,
  setStudents,
  setGroups,
  setFaculty,
  fetchStudentsFaled,
  setFunctionalGroups,
  fetchFunctionalGroups,
  setEmployee,
  sendMessageSuccess,
  sendMessageFailed,
  setSchedule
} from '../actions';
import axios, { buildUrlParams }  from '../api';

export function* editFunctionalGroup({ payload: { id, params } }) {
  try {
    yield axios.put(`api/functional-group/${id}`, params);
    yield put(fetchFunctionalGroups());

  } catch (error) {
    console.log('Error', error)
  }
}

export function* addStudentsToGroup({ payload: { id, params } }) {
  try {
    yield axios.put(`api/functional-group/${id}/students`, params);

  } catch (error) {
    console.log('Error', error)
  }
}

export function* deleteFunctionalGroup({ payload: { id } }) {
  try {
    yield axios.delete(`api/functional-group/${id}`);
    yield put(fetchFunctionalGroups());

  } catch (error) {
    console.log('Error', error)
  }
}

export function* addNewGroup({ payload: { params } }) {
  try {
    yield axios.post('api/functional-group', params);
    yield put(fetchFunctionalGroups());

  } catch (error) {
    console.log('Error', error)
  }
}

export function* fetchAllGroups() {
  try {
    const response = yield axios.get('api/functional-group');
    yield put(setFunctionalGroups(response.data));

  } catch (error) {
    console.log('Error', error)
  }
}

export function* fetchFaculty() {
  try {
    const response = yield axios.get(`/api/list/faculty`);
    yield put(setFaculty({
      faculty: response.data
    }));
  } catch (error) {

  }

}
export function* fetchEmployee({ payload: { faculty } }) {
  try {
    const response = yield axios.get(`/api/list/employee?faculty=${faculty}`);
    yield put(setEmployee({ employee: response.data }));
  } catch (error) {

  }
}

export function* fetchGroups({
  payload: {
    course
  }
}) {
  try {
    const response = yield axios.get(`/api/list/group?${buildUrlParams({ course })}`);
    yield put(setGroups({
      groups: response.data
    }));
  } catch (error) {
    yield put(fetchStudentsFaled())
  }

}

export function* fetchSchedule({
  payload: {
    language
  }
}) {
  try {
    const response = yield axios.get(`/api/schedule?${buildUrlParams({ language  })}`);
    yield put(setSchedule({
      schedule: response.data
    }));
  } catch (error) {

  }
}

export function* fetchCourse() {
  try {
    const response = yield axios.get('/api/list/course');
    yield put(setCourse({
      courses: response.data
    }));
  } catch (error) {

  }
}

export function* fetchStudents({
  payload: {
    ...filters
  }
}) {
  try {
    const response = yield axios.get(`/api/list/student?${buildUrlParams({ ...filters })}`);
    yield put(setStudents({
      students: response.data
    }));
  } catch (error) {

  }
}

export function* sendMessage({
  payload: {
    file,
    title,
    message,
    students,
    type,
    value,
    callBack,
    error: errorCallBack
  }
}) {
  const params = {
    title,
    message,
    students,
    type,
    value
  };
  try {
    if (file) {
      const response = yield axios.post('api/message/file', file);
      try {
        yield axios.post('api/message', {...params, files: [response.data.id]}).then(res => {callBack()}).catch(function (error) {
          if (error.response) {
            errorCallBack(error.response.data);
          } else {
            errorCallBack({errors: []});
          }
        });
        yield put(sendMessageSuccess());
      } catch (error) {
        yield put(sendMessageFailed());
        if (error.response) {
          errorCallBack(error.response.data);
        } else {
          errorCallBack({errors: []});
        }
      }
    } else {
        yield axios.post('api/message', params).then(res => callBack()).catch(function (error) {
          if (error.response) {
            errorCallBack(error.response.data);
          } else {
            errorCallBack({errors: []});
          }
        });
        yield put(sendMessageSuccess());
    }
  } catch (error) {
    yield put(sendMessageFailed());
    if (error.response) {
      errorCallBack(error.response.data);
    } else {
      errorCallBack({errors: []});
    }
  }
}

export function* uploadFile(payload) {
  try {
    const response = yield axios.get(`api/file/${payload.payload}`, { responseType: 'blob' });
    const type = response.data.type.split('/')[1];
    download(response.data, `file.${TYPES[type] || type}`)
  } catch (error) {}
}
