import React from 'react';
import { connect } from "react-redux";
import { Spin } from 'antd';

import CheckBoxComponent from '../../components/CheckBoxComponent/CheckBoxComponent';
import RadioButton from '../Radio/Radio';
import { studentsSelector, studentsIsLoading } from '../../selectors';
import s from './studentsList.module.scss';

const StudentsList = ({ students, allChecked, handleAllCheck, checkedStudents, handleCheckStundent, studentsIsLoading }) => {
  return (
    <div className={s.wrapper}>
      <div className={s.rightCheckbox}>
        <label>
          <span className={s.rightCheckbox_text}>отметить всех</span>
          <CheckBoxComponent callback={handleAllCheck} checked={allChecked} />
        </label>
      </div>
      <div className={s.tableWrapper}>
        <table>
          <tr>
            <th>Фамилия</th>
            <th>Имя</th>
            <th>Отчество</th>
            <th>№ зачетки</th>
            <th>Курс</th>
            <th>Группа</th>
            <th>Староста</th>
            <th>В рассылке</th>
          </tr>
          {studentsIsLoading && <tr>
            <th></th>
            <th></th>
            <th></th>
            <th><Spin size="large" /></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>}
          {!studentsIsLoading && students.map(student =>
            <tr >
              <th>{student.last_name}</th>
              <th>{student.first_name}</th>
              <th>{student.mid_name}</th>
              <th>{student.student_card_number}</th>
              <th>{student.group.course}</th>
              <th>{student.group.name}</th>
              <th><RadioButton checked={student.is_captain} /></th>
              <th>
                <CheckBoxComponent
                  callback={(v) => handleCheckStundent(v, student.id)}
                  checked={checkedStudents.includes(student.id)}
                />
              </th>
            </tr>
          )}
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  students: studentsSelector(state),
  studentsIsLoading: studentsIsLoading(state)
});

export default connect(mapStateToProps, null)(StudentsList);
