import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import qs from "qs";
import { FormattedMessage } from "react-intl";
import { Table, Pagination } from "antd";
import { injectIntl } from "react-intl";
import cn from "classnames";
import moment from "moment";
import axios from '../../api';

import MobilePaymentCard from "./MobilePaymentCard/MobilePaymentCard";
import Header from "../../components/Headers/MessagesHeader";
import headerStyles from '../../components/Headers/header.module.scss';
import { ROUTES } from "../../constants";
import { getProfile, loginRowguid } from "../../actions/authorisation";
import {
  paymentsRequest,
  setPayment,
  openPaymentModal,
  closePaymentModal,
} from "../../actions/payment";
import RefreshImg from "../../images/refresh.svg";
import s from "./Finance.module.scss";
import { CURRENCY_MAP } from "../../constants/currency";
import PaymentModal from "./PaymentModal/PaymentModal";

const columns = [
  {
    title: () => (
      <div className={cn(s.th, s.thPaymentName)}>
        <FormattedMessage id="paymentName" />
      </div>
    ),
    dataIndex: "paymentName",
    key: "paymentName",
  },
  {
    title: () => (
      <div className={cn(s.th, s.thServiceCost)}>
        <FormattedMessage id="serviceCost" />
      </div>
    ),
    dataIndex: "serviceCost",
    key: "serviceCost",
  },
  {
    title: () => (
      <div className={cn(s.th, s.thAmountOfInterest)}>
        <FormattedMessage id="amountOfInterest" />
      </div>
    ),
    render: (text, item) => {
      return <div className={cn({ [s.penaltyText]: (!item.payment_date || !item.nom_doc) })}>{text}</div>;
    },

    dataIndex: "amountOfInterest",
    key: "amountOfInterest",
  },
  {
    title: () => (
      <div className={cn(s.th, s.thExpiredDays)}>
        <FormattedMessage id="expiredDays" />
      </div>
    ),
    dataIndex: "expiredDays",
    key: "expiredDays",
  },
  {
    title: () => (
      <div className={cn(s.th, s.thTotalPayable)}>
        <FormattedMessage id="totalPayable" />
      </div>
    ),
    dataIndex: "totalPayable",
    key: "totalPayable",
  },
  {
    title: () => (
      <div className={cn(s.th, s.thPaymentCrediting)}>
        <FormattedMessage id="paymentCrediting" />
      </div>
    ),
    dataIndex: "paymentCrediting",
    key: "paymentCrediting",
  },
].map((c, i) => ({
  ...c,
  render: c.render
    ? c.render
    : (text) => <div style={{ whiteSpace: "normal" }}>{text}</div>,
}));

class Message extends React.Component {
  state = {
    linkToJournal: ''
  };

  componentDidMount() {
    const { paymentsRequest, getProfile, location, history } = this.props;

    const queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    // If rowguid token exist - sign in with rowguid, else if no authToken
    // in local storage - redirect to sign in, if everything going well
    // fetch profile;
    const currentPathname = location.pathname.split("/")[1];
    const userType = ROUTES[currentPathname];

    const authToken = localStorage.getItem(userType);

    if (queryParams.usr) {
      loginRowguid({ rowguid: queryParams.usr });
    } else if (!authToken) {
      history.push("/login/employee");
    } else {
      getProfile({ token: authToken, pathname: userType });
      paymentsRequest();
      this.getLink();
    }
  }

  getLink = () => {
    axios.get(`/api/journal`).then((response) => {
      this.setState({ linkToJournal: response.data.url });
    });
  };

  handleRefresh = () => {
    const { paymentsRequest, payments } = this.props;
    if (payments.isLoading) {
      return;
    }

    paymentsRequest({ forceRefresh: true });
  };

  handleRefreshPagination = (page, pageSize) => {
    const { paymentsRequest, payments } = this.props;
    if (payments.isLoading) {
      return;
    }

    paymentsRequest({ page });
  };

  formatAmount = (amount, currency) =>
    amount != 0 ? amount + " " + CURRENCY_MAP[currency] : 0;

  paymentCrediting = (paymentCrediting) =>
    paymentCrediting
      ? moment(paymentCrediting, "YYYY-MM-DD").format("DD.MM.YYYY")
      : "нет";

  onPayClick = (payment) => () => {
    const { openPaymentModal, setPayment } = this.props;

    setPayment({ paymentId: payment.name });
    openPaymentModal();
  };

  render() {
    const { intl, payments } = this.props;
    const { total, pageSize, page } = payments.pager;
    const { BYN, USD } = (payments.statistic && payments.statistic.debits) || {};

    const tableData = payments.data
      ? payments.data.map((payment) => ({
        ...payment,
        paymentName: payment.name,
        serviceCost: this.formatAmount(
          payment.amount,
          payment.currency
        ),
        amountOfInterest: this.formatAmount(
          (+payment.penalty_amount || 0) + (+payment.penalty_acc_amount || 0),
          payment.currency
        ),
        expiredDays: payment.delay,
        totalPayable: this.formatAmount(payment.total_amount, payment.currency),
        paymentCrediting: this.paymentCrediting(payment.payment_date),
        nPayment: payment.nom_doc,
      }))
      : [];

    const emptyText = intl.formatMessage({ id: "noData" });
    const pageTitle = intl.formatMessage({ id: "paymentData" });

    const tableTitle = intl.formatMessage({ id: "totalDebt" });
    const tableTitleNoTax = intl.formatMessage({ id: "totalDebtNoTax" });

    return (
      <div className={s.wrapper}>
        <PaymentModal />
        <div className={s.content}>
          <Header linkToJournal={this.state.linkToJournal} headWrapper={headerStyles.wrapper2} />

          <div className={s.head}>
            <div className={s.title}>{pageTitle}</div>
          </div>

          <div className={s.tableWrapper}>
            <div className={s.tableHeader}>
              {(BYN || USD) && (
                <>
                  <div className={s.totalDebtTitle}>{tableTitle}:</div>&nbsp;&nbsp;
                  {BYN !== undefined && (<><div className={s.totalDebtButton}>{BYN}р.</div>&nbsp;&nbsp;</>)}
                  {USD !== undefined && (<><div className={s.totalDebtButton}>{USD}$</div>&nbsp;&nbsp;</>)}
                </>
              )}
              {(!BYN && !USD) && (
                <>
                  <div className={s.totalDebtTitleNoTax}>{tableTitleNoTax}</div>&nbsp;&nbsp;
                </>
              )}
              <img
                src={RefreshImg}
                className={s.reloadPaymentsButton}
                onClick={this.handleRefresh}
                alt='refresh'
              />
            </div>
            <div style={{ maxWidth: "100%" }} className={s.paymentTable}>
              <Table
                locale={{ emptyText }}
                dataSource={tableData}
                scroll={{ x: true }}
                pagination={false}
              >
                {columns.map((c) => (
                  <Table.Column {...c} />
                ))}
                <Table.Column
                  title={() => <FormattedMessage id="nPayment" />}
                  dataIndex="nPayment"
                  key="nPayment"
                  render={(text, record) =>
                    text ? (
                      text
                    ) : (
                        <button className={s.payBtn} onClick={this.onPayClick(record)}>
                          Оплатить
                        </button>
                      )
                  }
                />
              </Table>
            </div>

            <div className={s.mobilePaymentCards}>
              {tableData.map((payment) => (
                <MobilePaymentCard
                  payment={payment}
                  onPayClick={this.onPayClick(payment)}
                />
              ))}
            </div>
          </div>
          <div className={s.paginationWrapper}>
            <Pagination total={total} current={page} pageSize={pageSize} onChange={this.handleRefreshPagination} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.profile,
  payments: state.payment.payments,
});

const mapDispatchToProps = {
  paymentsRequest,
  getProfile,
  loginRowguid,
  setPayment,
  openPaymentModal,
  closePaymentModal,
};

export default compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(Message);
