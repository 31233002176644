import React from 'react';
import { Select } from 'antd';
import { connect } from "react-redux";

import { fetchGroups, clearStudents } from '../../actions';
import { coursesSelector } from '../../selectors';

const SelectComponent = ({ options, fetchStudents, fetchGroups, value, handleClear, clearStudents }) => {
  function onChange(course) {
    if (course) {
      fetchStudents(course, 'course');
      fetchGroups({ course })
    } else {
        clearStudents();
        handleClear();
    }
  }

  const { Option } = Select;
  const displayOptions = options.map(o => <Option key={o} value={o}>{o} курс</Option> );
  return (
    <Select
    style={{ width: '100%' }}
    placeholder="Выберите курс"
    optionFilterProp="children"
    onChange={onChange}
    value={value}
    allowClear = 'true'
  >
    {displayOptions}
  </Select>
  );
};

const mapStateToProps = state => ({
  options: coursesSelector(state)
});

export default connect(mapStateToProps, { fetchGroups, clearStudents })(SelectComponent);
