import { put } from 'redux-saga/effects';
import axios from '../api';

import { ROUTES } from '../constants';
import { getProfile } from '../actions/authorisation';
import { addProfile, setError, deleteLoginPassword } from '../actions/authorisation';
import history from "../history";
import {passwordFrom7to20} from "../utils/validator";
import moment from "moment";

export function* loginRowguid({ payload: { rowguid }}) {
  try {
    axios.defaults.headers.common.Authorization = null;
    const response = yield axios.post( 'api/login/student/rowguid', { rowguid });
    localStorage.setItem('student', response.data.token);
    yield put (getProfile({ token:response.data.token, redirect: 'login' }));
  } catch (error) {
    history.push('/login/student');
  }
}

export function* loginSaga({ payload: { pathname, login, password, localisationMode}}) {
  try {
    axios.defaults.headers.common.Authorization = null;
    const response = yield axios.post( `api/login/${pathname}`, { password, login });
    localStorage.setItem(pathname, response.data.token);
    yield put (getProfile({ token:response.data.token, redirect: 'login' }));
  } catch (error) {
    if (localisationMode) {
      yield put(setError('К сожалению, такой пользователь не найден. Проверьте правильность введенных данных и повторите попытку'));
    } else yield put(setError('Sorry, the user is not found. Please check your input and try again'));
  }
}

export function* restorePasswordSaga({ payload: { pathname, login, student_card_number, language} }) {

  try {
    axios.defaults.headers.common.Authorization = null;
    const response = yield axios.post( `api/student/password-recovery`, { login, student_card_number, language });
    localStorage.setItem(pathname, response.data);
    yield put (getProfile({ token:response.data, redirect: 'login' }));
  } catch (error) {
    const message = error?.response?.data?.errors?.[0];
    if (message === "error.user.not-found-by-login-and-card-number") {
      if (language === "ru") {
        yield put(setError('Данной комбинации логина и номера студенческого билета не существует'));
      } else yield put(setError('Incorrect login or student ID number'));
    } else  if (message === "error.user.password-restore-delay") {
      if (language === "ru") {
        yield put(setError('Ошибка смены пароля. Попробуйте позже.'));
      } else yield put(setError('Error. Try again later'));
    }

  }
}

export function* changePasswordSaga({ payload: { login, password, new_password, new_password_repeat, language} }) {

  if (!passwordFrom7to20(password) || !passwordFrom7to20(new_password) || !passwordFrom7to20(new_password_repeat) ) {
    if (language === "ru") {
      yield put(setError('Ошибка. Размер пароля может быть от 7 до 20 символов.'));
    } else yield put(setError('Error. Password size can be from 7 to 20 characters.'));
  } else if (new_password !== new_password_repeat) {
    if (language === "ru") {
      yield put(setError('Новые пароли не совпадают'));
    } else yield put(setError('New passwords do not match'));
  } else {
    try {
      axios.defaults.headers.common.Authorization = null;
      const response = yield axios.post( `api/student/change-password`, { login, password, new_password, new_password_repeat, language });
      if (response) {
        history.push('/login/student');
      }
    } catch (error) {
      const message = error?.response?.data?.errors?.[0];

      if (message === "error.user.wrong-credentials") {
        if (language === "ru") {
          yield put(setError('Данной комбинации логина и пароля не существует'));
        } else yield put(setError('Incorrect login or password'));
      } else if (message === "error.user.password-change-error" || message === "error.user.password-change-delay") {
        if (language === "ru") {
          yield put(setError('Ошибка смены пароля. Попробуйте позже.'));
        } else yield put(setError('Error. Try again later'));
      }
    }
  }
}

export function* getProfileSaga({payload}) {
  try {
    axios.defaults.headers.common.Authorization = `Bearer ${payload.token}`;
    const profile = yield axios.get (`api/profile`);
    const exprDate = profile.data?.password_changed_at;
    if (payload.redirect === 'login') {
      if (profile.data.discr === 'employee' && profile.data.is_rector) {
        if (!exprDate) {
          history.push('/change-password');
        } else {
          history.push('/rector-mail-history');
        }
      }
      if ( profile.data.discr === 'employee' && profile.data.is_admin) {
        if (!exprDate) {
          history.push('/change-password');
        } else {
          history.push('/mailing');
        }
      }
      if ( profile.data.discr === 'employee' && !profile.data.is_rector) {
        if (!exprDate) {
          history.push('/change-password');
        } else {
          history.push('/mailing');
        }
      }
      if ( profile.data.discr === 'student' ) {
        if (!exprDate) {
          history.push('/change-password');
        } else {
          history.push('/messages');
        }
      }
    } else {
      if (!exprDate) {
        history.push('/change-password');
      } else {
        history.push(payload.redirect);
      }
    }
    yield put(addProfile(profile.data));
  } catch (error) {
    console.error('Error while get profile: ', error)
    history.push(`/login/${ROUTES[payload.pathname]}`);
  }
}

export function* logout({ payload: { pathname }}) {
  try {
    yield axios.post( `api/logout/${ROUTES[pathname]}`);
    localStorage.setItem(pathname, null);
    history.push(`/login/${ROUTES[pathname]}`);
    yield put(deleteLoginPassword());
  } catch (error) {
    yield put(setError('К сожалению, такой пользователь не найден. Проверьте правильность введенных данных и повторите попытку'));
  }
}
