import React from 'react';
import { Input } from 'antd';
import s from './inputComponent.module.scss'

const InputComponent = ({ label, placeholder, inputCallback, inputValue, type }) => {

	const changeInput = (e) => inputCallback(e);

	return (
		<div className={s.wrapper}>
			<span className={s.label} >
				{label}
			</span>
			{
				type === 'password' ? 
				<Input.Password placeholder = "input password" 
												value = { inputValue }
												onChange = { changeInput } /> :
				<Input placeholder = { placeholder } onChange = { changeInput } value = { inputValue } />

			}
		</div>
	);
};

export default InputComponent;
