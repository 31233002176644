import React from 'react';
import { Input, Select, Icon } from 'antd';
import { connect } from "react-redux";
import { get, isEmpty } from 'lodash';

import AddStudentsToGroup from "../../pages/Groups/AddStudentsToGroup";
import { fetchStudents, clearStudents } from '../../actions';
import { facultySelector } from '../../selectors';
import SelectComponent from '../SelectComponent/SelectComponent';
import CheckBoxComponent from '../CheckBoxComponent/CheckBoxComponent';
import GroupsList from '../GroupsList/GroupsList';
import SendForm from '../SendForm/SendForm';
import s from './mailingBlock.module.scss';
import './mailingBlock.scss';

const Search = Input.Search;
const { Option } = Select;

class MailingBlock extends React.Component {
  state = {
    name: '',
    course: undefined,
    faculty: undefined,
    group: [],
    is_captain: false,
    allStudents: false,
    showModal: false,
    functionalGroup: undefined
  }

  componentDidMount() {
    this.props.clearStudents();
  }

  clearCours = () => {
    this.setState({ course: undefined, group: [] }, () => this.handleFilter());
  }

  fetchStudents = () => {
    const { name, course, is_captain, group: groupList, faculty, functionalGroup } = this.state;
    const group = !isEmpty(groupList) && groupList;
    this.props.fetchStudents({ name, course, group, is_captain, faculty, functional_group: functionalGroup });
  }

  handleFilter = () => {
    this.fetchStudents();
  }

  handleSelectFilter = (value, type) => {
    this.setState({ [type]: value, allStudents: false }, () => this.handleFilter())
  }

  handleAllStudents = (value) => {
    this.setState({ allStudents: value, name: '', faculty: undefined, course: undefined, group: [], is_captain: false }, () => this.handleFilter())
  }

  addGroup = (currentGroup, isCheked) => {
    if (isCheked && !this.state.group.includes(currentGroup)) {
      this.setState(state => ({ group: state.group.concat(currentGroup) }), () => this.fetchStudents())
    } else {
        const groupArray = this.state.group.filter(g => g !== currentGroup);
        this.setState(state => ({ group: groupArray }), () => this.fetchStudents())
    }
  }

  onClose = () => this.setState({ showModal: false });

  render() {
    const { user: { profile }, checkedStudents, allChecked, faculties, functionalGroups } = this.props;
    const { name, course, group, is_captain, allStudents, functionalGroup, faculty } = this.state;
    const displayOptions = faculties.map((o) => (
      <Option key={o} value={o.id}>
        {o.name}
      </Option>
    ));
    const displayGroupsOptions = functionalGroups.map((o) => (
      <Option key={o} value={o.id}>
        {o.title}
      </Option>
    ));
    return (
      <div className={s.wrapper}>
        <div className={s.title}>{get(profile, 'faculty.name')}</div>
        {get(profile, 'is_admin') && <div className='selectContainer'>
          <Select
            style={{ width: '100%' }}
            placeholder="Выберите факультет"
            optionFilterProp="children"
            onChange={(value) => this.handleSelectFilter(value, 'faculty')}
            value={this.state.faculty}
            allowClear={true}
          >
            {displayOptions}
          </Select>
        </div>}
        <div className={s.inputContainer}>
          <Search
            value={this.state.name}
            placeholder="Diego Sanchez"
            style={{ width: '100%' }}
            onChange={(e) => this.handleSelectFilter(e.currentTarget.value, 'name')}
          />
        </div>
        <div className='selectContainer'>
          <Select
            style={{ width: '100%' }}
            placeholder="Выберите функциональную группу"
            optionFilterProp="children"
            onChange={(value) => this.handleSelectFilter(value, 'functionalGroup')}
            value={this.state.functionalGroup}
            allowClear={true}
          >
            {displayGroupsOptions}
          </Select>
        </div>
        <div className='selectContainer' onClick={() => this.setState({ showModal: true })}>
          <Icon type="plus-circle" style={{ marginRight: '10px', fontSize: '22px' }}/>
          <span>Добавить студента в выбранную группу</span>
        </div>
        <AddStudentsToGroup
          showModal={this.state.showModal}
          onCancel={this.onClose}
          displayGroupsOptions={displayGroupsOptions}
          functionalGroup={this.state.functionalGroup}
          handleChangeGroup={(value) => this.setState({ functionalGroup: value })}
          checkedStudents={checkedStudents}
        />
        <div className='selectContainer'>
          <SelectComponent value={this.state.course} fetchStudents={this.handleSelectFilter}
          handleClear = {this.clearCours} />
        </div>
        <div className={s.checkboxContainer}>
          <label>
            <CheckBoxComponent
              checked={this.state.is_captain}
              callback={(value) => this.handleSelectFilter(value, 'is_captain')}
            />
            <span className={s.text}>только старостам</span>
          </label>
          <label>
            <CheckBoxComponent
              checked={this.state.allStudents}
              callback={this.handleAllStudents}
            />
            <span className={s.text}>всем студентам</span>
          </label>
        </div>
        <GroupsList fetchStudents={this.handleSelectFilter} course={this.state.course} currentCroup={this.state.group} addGroup = {this.addGroup} />
        <SendForm filters={{name, course, group, is_captain, allStudents, allChecked, functionalGroup, faculty}} checkedStudents={checkedStudents} />
      </div>
    );
  }
};

const mapStateToProps = state => ({
  user: state.user,
  faculties: facultySelector(state),
  functionalGroups: state.root.functionalGroups.items
});

const mapDispatchToProps = {
  fetchStudents,
  clearStudents
};

export default connect(mapStateToProps, mapDispatchToProps)(MailingBlock);
