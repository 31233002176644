import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get, isEqual, isEmpty } from 'lodash';
import { Icon } from 'antd';

import { fetchFunctionalGroups, destroyFunctionalGroup } from '../../actions';
import { ROUTES } from '../../constants';
import Header from '../../components/Headers/MaillingHeader';
import AddNewGroup from './AddNewGroup';
import EditGroup from './EditGroup';
import { authorisationHoc } from '../../hoc/authorusationHoc';
import { getProfile } from '../../actions/authorisation';
import s from './index.module.scss';

class GroupsPage extends React.Component {
  state = {
    showEditModal: {},
    showNewModal: false
  };

  componentDidMount() {
    if (this.props.user) {
      this.props.fetchFunctionalGroups();
    } else {
      const key = this.props.location.pathname.split('/')[1];
      this.props.getProfile({
        token: localStorage.getItem(ROUTES[key]),
        pathname: key,
        redirect: this.props.location.pathname
      });
    }
  }

  componentWillReceiveProps({ user, functionalGroups }) {
    if (!isEqual(user, this.props.user) && user) {
      this.props.fetchFunctionalGroups();
    }

    if (!isEqual(functionalGroups, this.props.functionalGroups)) {
      this.onCloseNewModal();
      this.onCloseEditModal();
    }
  }

  onCloseNewModal = () => this.setState({ showNewModal: false });
  onCloseEditModal = () => this.setState({ showEditModal: {} });

  render() {
    const { user, functionalGroups } = this.props;
    const { showEditModal } = this.state;

    return (
      <div className={s.wrapper}>
        <div className={s.content}>
          {user && <Header rector={get(user, 'is_rector')} />}
          <div className={s.title}>
            <div className={s.titleWrapper} onClick={() => this.setState({ showNewModal: true })}>
              <Icon type="plus-circle" style={{ marginRight: '10px', fontSize: '22px' }}/>
              <span>Создать новую функциональную группу</span>
            </div>
          </div>
          <AddNewGroup showModal={this.state.showNewModal} onCancel={this.onCloseNewModal} />
          <EditGroup showModal={!isEmpty(showEditModal)} onCancel={this.onCloseEditModal} editGroup={showEditModal} />
          <div className={s.table}>
            <div className={s.tableWrapper}>
              <div className={s.head}>
                <div className={s.firstBlock}>
                  Наименование группы
                </div>
                <div className={s.secondBlock}>
                  Описание группы
                </div>
                <div className={s.thirdBlock}>
                </div>
              </div>
              {functionalGroups && functionalGroups.map(message =>
                <div key={message.id}>
                  <div className={s.message}>
                    <div className={s.firstBlock}>
                      <div className={s.messageTitle}>
                        {message.title}
                      </div>
                    </div>
                    <div className={s.secondBlock}>
                      {message.description}
                    </div>
                    <div className={s.thirdBlock}>
                      <Icon type="form" className={s.icon} style={{ marginRight: '30px' }} onClick={() => this.setState({ showEditModal: message })}/>
                      <Icon type="close" className={s.icon} onClick={() => this.props.destroyFunctionalGroup({ id: message.id })}/>
                    </div>
                  </div>
                  <div className={s.border}></div>
                </div>
              )}
            </div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.profile,
    functionalGroups: state.root.functionalGroups.items
  };
};

const mapDispatchToProps = {
  getProfile,
  fetchFunctionalGroups,
  destroyFunctionalGroup
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(authorisationHoc(GroupsPage));
