import React from 'react';
import { Checkbox } from 'antd';
import "antd/dist/antd.css";
import './checkBoxComponent.scss'

const CheckBoxComponent = ({ text, callback, checked }) => {
  function onChange(e) {
    callback(e.target.checked);
  }
  return (
    <div className='checkBoxComponent'>
      <Checkbox checked={checked} onChange={onChange}>{text}</Checkbox>
    </div>
  );
};

export default CheckBoxComponent;
