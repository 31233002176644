import React from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { loginSelector, passwordSelector, errorSelector, localisationModeSelector } from "../selectors/userSelectors";
import Authorization from '../pages/Authorization/Authorization';
import { loginOnChange, passwordOnChange, login, changeLocalisationMode } from "../actions/authorisation"
import { toggleModal } from "../actions/feedbackModal"

class AuthorizationStudentContainer extends React.Component {
  render() {    
    let title;
    if (this.props.location.pathname === '/login/employee') title = 'Вход для сотрудников БГМУ';
    if (this.props.location.pathname === '/login/student') { title = <FormattedMessage id="authorisationStudentTitle" />};
    return (
      <>
        <Authorization  loginOnChange = {this.props.loginOnChange}
                        passwordOnChange = {this.props.passwordOnChange}
                        login = { this.props.login }
                        loginText = {this.props.loginText }
                        passwordText = {this.props.passwordText }
                        error = { this.props.error }
                        title = {title}
                        changeLocalisationMode = {this.props.changeLocalisationMode}
                        toggleModal={this.props.toggleModal}
                        localisationMode = {this.props.localisationMode} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginText: loginSelector(state),
    passwordText: passwordSelector(state),
    error: errorSelector(state),
    localisationMode: localisationModeSelector(state), 
  }
};

const mapDispatchToProps = {
  loginOnChange,
  passwordOnChange,
  login,
  changeLocalisationMode,
  toggleModal
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(AuthorizationStudentContainer);
