export const initialState = {
  messages: [],
  currentPage: 1,
  hasMore: false,
  unread: false,
  isCleanRequared: false,
  currentMessage: [],
  isSending: false,
  sendError: false
};
