export const constants = {
  paymentsRequest: "paymentsRequest",
  paymentsSuccess: "paymentsSuccess",
  paymentsFailure: "paymentsFailure",

  reloadPaymentsRequest: "reloadPaymentsRequest",
  reloadPaymentsSuccess: "reloadPaymentsSuccess",
  reloadPaymentsFailure: "reloadPaymentsFailure",

  setPayment: "setPayment",

  openPaymentModal: "openPaymentModal",
  closePaymentModal: "closePaymentModal",
};
