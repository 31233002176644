import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Logout from "../Logout/Logout";
import s from "./header.module.scss";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { toggleModal } from "../../actions/feedbackModal";

import styles from "./header.module.scss";

const MessagesHeader = ({
  location,
  rector,
  linkToJournal,
  headWrapper = s.wrapper,
  toggleModal,
}) => {
  const active = `${s.link} ${s.selected}`;
  const unActive = `${s.link}`;

  return (
    <div className={headWrapper}>
      <div className={s.linksBlock}>
        {!rector && (
          <div
            className={location.pathname === "/messages" ? active : unActive}
          >
            <NavLink to={"/messages"}>
              <FormattedMessage id="messages" />
            </NavLink>
          </div>
        )}

        <div className={unActive}>
          <a href={linkToJournal}>
            <FormattedMessage id="journal" />
          </a>
        </div>
        <div className={unActive}>
          <a href="https://www.bsmu.by/activity/#current">
            <FormattedMessage id="activity" />
          </a>
        </div>

        {!rector && (
          <div
            className={location.pathname === "/schedule" ? active : unActive}
          >
            <NavLink to={"/schedule"} activeClassName="active">
              <FormattedMessage id="schedule" />
            </NavLink>
          </div>
        )}

        <div className={location.pathname === "/payments" ? active : unActive}>
          <NavLink to={"/payments"} activeClassName="active">
            <FormattedMessage id="payments" />
          </NavLink>
        </div>

        <div className={active}></div>
      </div>
      <div className={styles.actions}>
        <div className={styles.feedback} onClick={toggleModal}>
          <div />
          <FormattedMessage id="feedback" />
        </div>
        <Logout />
      </div>
    </div>
  );
};

const mapDispatch = { toggleModal };

export default compose(withRouter, connect(null, mapDispatch))(MessagesHeader);
