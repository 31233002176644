import React from 'react';
import { compose } from 'redux';
import { connect } from "react-redux";
import AuthorizationStudentContainer from './containers/AuthorisationContainer';
import HistoryMail from './pages/Mail/HistoryMail/HistoryMail';
import Messages from './pages/Messages/Messages';
import Message from './pages/Message/Message';
import Mailing from './pages/Mail/Mailing/Mailing';
import Groups from './pages/Groups';
import Schedule from './pages/Schedule/Schedule';
import Finance from './pages/Finance/Finance';
import FeedbackModal from './components/FeedbackModal/FeedbackModal';
import './App.css';
import RestorePassword from "./pages/RestorePassword/RestorePassword";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import {Route, Switch} from "react-router-dom";


const App = (props) => {
  return (
    <>
      <Switch>
        <Route path="/rector-mail-history" component={HistoryMail} />
        <Route path="/mail-history" component={HistoryMail} />
        <Route path="/groups" component={Groups} />
        <Route path="/messages/:id" component={Message} />
        <Route path="/messages" component={Messages} />
        <Route path="/mailing" component={Mailing} />
        <Route path="/schedule" component={Schedule} />
        <Route path="/payments" component={Finance} />
        <Route path="/login/student" component={AuthorizationStudentContainer} />
        <Route path="/login/employee" component={AuthorizationStudentContainer} />
        <Route path="/password-recovery" component={RestorePassword} />
        <Route path="/change-password" component={ChangePassword} />
      </Switch>

      <FeedbackModal isOpen={props.isFeedbackModalOpen} />
    </>
  );
}

const mapState = state => ({
  isFeedbackModalOpen: state && state.feedbackModal.isOpen,
});

export default compose(connect(mapState))(App);
