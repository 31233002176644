import React from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import { Icon } from 'antd';
import { errorSelector } from '../../selectors/userSelectors';
import { clearErrorMessage } from '../../actions/authorisation';
import s from './errorMessage.module.scss';

const ErrorMessage = ({ error, clearErrorMessage }) => {

  return (
    <div className={s.wrapper}>
      {error}
      <div className = {s.iconContainer} onClick = { clearErrorMessage }>
        <Icon type="close" style={{color: 'gray'}} onClick = { clearErrorMessage }/>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    error: errorSelector(state),
  }
};

const mapDispatchToProps = {
  clearErrorMessage
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ErrorMessage);
