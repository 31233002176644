import React from "react";
import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import ru from "react-intl/locale-data/ru";

const enTranslation = {
  //authorisation page
  authorisationStudentTitle: "BSMU Student Personal Account",
  authorisationButton: "Login",
  insertLogin: "Insert login:",
  supportService: "Support service",
  phoneOrEmail: "Phone or email*",
  inSupportService: "in support service",
  inDeanery: "in deanery",
  headingText: "Heading text*",
  messageText: "Message text*",
  sendMessagePolicy: "By sending a message you agree with",
  send: "Send",
  securePolicy: "Security policy",
  insertPassword: "Password:",
  restorePassword: 'Password recovery',
  changePassword: 'Change password',
  //restorePassword page
  passwordRecovery: 'Password recovery',
  login: 'Login',
  idNum: 'Student ID number',
  //changePassword page
  oldPassword: 'Old password',
  newPassword: 'New password',
  repeatPassword: 'Repeat new password',
  //logout component
  logout: "Log out",
  //messages page
  journal: "Gradebook",
  activity: "Events",
  messagesNotFound: "Message could not be found",
  checkUnreadMessages: "Unread only",
  loadingMore: "Loading more",
  //message page
  back: "Back",
  previousMessage: "previous",
  nextMessage: "next",
  messages: "Messages",
  //schedule page
  schedule: "Schedule",
  course: "Course",
  // feedback
  feedback: "Feedback",

  "error.required": "Required field",
  "error.maxLength1000": "Must be 1000 characters or less",
  "error.maxLength50": "Must be 50 characters or less",
  "error.minLength2": "Must be 2 characters or more",
  "error.active": "Must be activated",

  "error.failToSendFeedback": "Fail to sent feedback",

  "success.feedbackSent": "Feedback successfully sent",

    // Finance
  payments: "Payments",
  paymentData: "Payment data",
  paymentName: "Payment name",
  totalDebt: "Total debt",
  totalDebtNoTax: "There is no debt",
  serviceCost: "Service cost",
  amountOfInterest: "Amount of interest",
  expiredDays: "Expired, days",
  totalPayable: "Total payable",
  paymentCrediting: "Payment crediting",
  nPayment: "N payment",
  pay: "Pay",
  noData: "No data",
  // Payment modal
  paymentReceiptGenerated: "Payment receipt has been generated",
  yourPaymentERIPNumber: "Your payment number in EPIP",
  paymentNumberIsValid24Hours: "Payment number is valid 24 hours",
  downloadInstructionsForPayment: "Download instructions for payment in eip",
  readInstructionsForPayment: "Link to instructions for payment through the \"Settlement\" system (EIP)",
  contactSupport: "Contact support",
};

const ruTranslation = {
  //authorisation page
  authorisationStudentTitle: "Личный кабинет студента БГМУ",
  authorisationButton: "Войти",
  insertLogin: "Введите логин:",
  supportService: "Служба поддержки",
  phoneOrEmail: "Телефон или email*",
  inSupportService: "в службу поддержки",
  inDeanery: "в деканат",
  headingText: "Текст заголовка*",
  messageText: "Текст сообщения*",
  sendMessagePolicy: "Отправляя сообщение вы соглашаетесь с",
  send: "Отправить",
  securePolicy: "Политикой безопасности",
  insertPassword: "Пароль:",
  restorePassword: 'Восстановление пароля',
  changePassword: 'Смена пароля',
  //restorePassword page
  passwordRecovery: 'Восстановление пароля',
  login: 'Логин',
  idNum: 'Номер студенческого билета',
  //changePassword page
  oldPassword: 'Старый пароль',
  newPassword: 'Новый пароль',
  repeatPassword: 'Повторите новый пароль',
  //logout component
  logout: "Выйти из аккаунта",
  //messages page
  journal: "Журнал успеваемости",
  activity: "События",
  checkUnreadMessages: "Только непрочитанные",
  loadingMore: "Показать еще",
  messagesNotFound: "Сообщений не найдено",
  //message page
  back: "Назад",
  previousMessage: "Предыдущее",
  nextMessage: "Следующее",
  messages: "Сообщения",
  //schedule page
  schedule: "Расписание",
  course: "Курс",
  // feedback
  feedback: "Обратная связь",

  "error.required": "Обязательное поле",
  "error.maxLength1000": "Должно содержать не более 1000 символов",
  "error.maxLength50": "Должно содержать не более 50 символов",
  "error.minLength2": "Должно содержать не менее 2 символов",
  "error.failToSendFeedback": "Не удалось отправить отзыв",
  "error.active": "Должно быть активировано",

  "success.feedbackSent": "Отзыв успешно отправлен",

    // Finance
  payments: "Финансы",
  paymentData: "Данные по оплатам",
  totalDebt: "Общая задолженность",
  totalDebtNoTax: "Задолженности нет",
  paymentName: "Наименование платежа",
  serviceCost: "Стоимость услуги",
  amountOfInterest: "Сумма пени",
  expiredDays: "Просрочено, дней",
  totalPayable: "Итого к оплате",
  paymentCrediting: "Зачисление оплаты на р/с",
  nPayment: "N платежа",
  pay: "Оплатить",
  noData: "Данных нет",

  // Payment modal
  paymentReceiptGenerated: "Сформирована платежная квитанция",
  yourPaymentERIPNumber: "Ваш номер оплаты в ерип",
  paymentNumberIsValid24Hours: "Номер платежа действителен 24 часа",
  downloadInstructionsForPayment: "Скачать инструкцию по оплате в ерип",
  readInstructionsForPayment: "Ссылка на инструкцию по оплате через систему «Расчет» (ЕРИП)",
  contactSupport: "Обратиться в службу поддержки",
};

addLocaleData([...en, ...ru]);

const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
  constructor(...args) {
    super(...args);

    this.switchToEnglish = () =>
      this.setState({ locale: "en", messages: enTranslation });

    this.switchToRussian = () =>
      this.setState({ locale: "ru", messages: ruTranslation });

    // pass everything in state to avoid creating object inside render method (like explained in the documentation)
    this.state = {
      locale: "ru",
      messages: ruTranslation,
      switchToEnglish: this.switchToEnglish,
      switchToRussian: this.switchToRussian,
    };
  }

  render() {
    const { children } = this.props;
    const { locale, messages } = this.state;
    return (
      <Context.Provider value={this.state}>
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="ru"
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    );
  }
}

export { IntlProviderWrapper, Context as IntlContext };
