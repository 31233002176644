import { put } from "redux-saga/effects";
import { get } from "lodash";
import { notification } from "antd";

import { paymentsSuccess, paymentsFailure } from "../actions/payment";
import axios from "../api";

import { buildUrlParams } from "../api";

// const stub = {
//   items: [
//     {
//       name: "Name",
//       total_amount: 200,
//       penalty_amount: 1,
//       delay: 22,
//       amount: "1000",
//       created_at: new Date(),
//       nom_doc: "12312313",
//       erip_id: "12312312",
//       currency: "USD",
//     },
//     {
//       name: "Name",
//       total_amount: 200,
//       penalty_amount: 1,
//       delay: 22,
//       amount: "1000",
//       created_at: new Date(),
//       nom_doc: "12312313",
//       erip_id: "12312312",
//       currency: "USD",
//     },
//     {
//       name: "Name",
//       total_amount: 200,
//       penalty_amount: 1,
//       delay: 22,
//       amount: "1000",
//       created_at: new Date(),
//       nom_doc: "12312313",
//       erip_id: "12312312",
//       currency: "USD",
//     },
//     {
//       name: "Name",
//       total_amount: 200,
//       penalty_amount: 1,
//       delay: 22,
//       amount: "1000",
//       created_at: new Date(),
//       nom_doc: "12312313",
//       erip_id: "12312312",
//       currency: "USD",
//     },
//     {
//       name: "Name",
//       total_amount: 200,
//       penalty_amount: 1,
//       delay: 22,
//       amount: "1000",
//       created_at: new Date(),
//       nom_doc: "12312313",
//       erip_id: "12312312",
//       currency: "USD",
//     },
//     {
//       name: "Name",
//       total_amount: 200,
//       penalty_amount: 1,
//       delay: 22,
//       amount: "1000",
//       created_at: new Date(),
//       nom_doc: "12312313",
//       erip_id: "12312312",
//       currency: "USD",
//     },
//     {
//       name: "Name",
//       total_amount: 200,
//       penalty_amount: 1,
//       delay: 22,
//       amount: "1000",
//       created_at: new Date(),
//       nom_doc: "12312313",
//       erip_id: "12312312",
//       currency: "USD",
//     },
//     {
//       name: "Name",
//       total_amount: 2001,
//       penalty_amount: 5,
//       delay: 11,
//       amount: "1000",
//       created_at: new Date(),
//       nom_doc: null,
//       erip_id: "12312312",
//       currency: "USD",
//     },
//   ],
// };
export function* fetchPayments(action) {
  const limit = "10";
  let page = "1";

  try {
    if (action.payload && action.payload.page !== undefined) {
      page = action.payload.page;
    }

    const params = buildUrlParams({ page, limit });

    let response;

    if (action.payload && action.payload.forceRefresh) {
      response = yield axios.put(`api/payments?${params}`);
    } else {
      response = yield axios.get(`api/payments?${params}`);
    }

    if (response.status === 200) {
      const { pager, items: data, statistic } = response.data;
      yield put(
        paymentsSuccess({
          pager: {
            pageSize: pager.items_per_page,
            page: pager.page,
            pages: pager.pages,
            total: pager.total_count,
          },
          data: data,
          statistic,
        })
      );
      return;
    }

    yield put(paymentsFailure());
  } catch (error) {
    console.error("Error while fetching payments", error);

    yield put(paymentsFailure());

    const message = get(action, "payload.constants.error");
    if (message) {
      notification.error({ message });
    }
  }
}

export function* reloadPayments(action) {
  const limit = "10";
  const page = "1";

  try {
    const params = buildUrlParams({ page, limit });

    const response = yield axios.get(`api/payments?${params}`);

    if (response.status === 200) {
      const { pager, items: data } = response.data;
      yield put(
        paymentsSuccess({
          pager: {
            pageSize: pager.items_per_page,
            page: pager.page,
            pages: pager.pages,
            total: pager.total_count,
          },
          data: data,
        })
      );
      return;
    }

    yield put(paymentsFailure());
  } catch (error) {
    console.error("Error while fetching payments", error);

    yield put(paymentsFailure());

    const message = get(action, "payload.constants.error");
    if (message) {
      notification.error({ message });
    }
  }
}
