import {
    createAction
} from "redux-actions";

export const fetchCourse = createAction('FETCH_COURSE');
export const setCourse = createAction('SET_COURSE');
export const fetchStudents = createAction('FETCH_STUDENTS');
export const fetchStudentsFaled = createAction('FETCH_STUDENTS_FALED');
export const setStudents = createAction('SET_STUDENTS');
export const clearStudents = createAction('CLEAR_STUDENTS');
export const sendMessage = createAction('SEND_MESSAGE');
export const sendMessageSuccess = createAction('SEND_MESSAGE_SUCCESS');
export const sendMessageFailed = createAction('SEND_MESSAGE_FAILED');
export const fetchGroups = createAction('FETCH_GROUPS');
export const setGroups = createAction('SET_GROUPS');
export const uploadFile = createAction('UPLOAD_FILE');
export const fetchFaculty = createAction('FETCH_FACULTY');
export const setFaculty = createAction('SET_FACULTY');
export const clearFaculty = createAction('CLEAR_FACULTY');
export const fetchEmployee = createAction('FETCH_EMPLOYEE');
export const setEmployee = createAction('SET_EMPLOYEE');
export const clearEmployee = createAction('CLEAR_EMPLOYEE');
export const createGroupStart = createAction('CREATE_GROUP_START');
export const createGroupSuccess = createAction('CREATE_GROUP_SUCCESS');
export const fetchFunctionalGroups = createAction('FETCH_FUNCTIONAL_GROUPS');
export const setFunctionalGroups = createAction('SET_FUNCTIONAL_GROUPS');
export const destroyFunctionalGroup = createAction('DESTROY_FUNCTIONAL_GROUP');
export const addStudentsToFunctionalGroup = createAction('ADD_STUDENTS_TO_FUNCTIONAL_GROUP');
export const updateFunctionalGroup = createAction('UPDATE_FUNCTIONAL_GROUP');
export const fetchSchedule = createAction('FETCH_SCHEDULE');
export const setSchedule = createAction('SET_SCHEDULE');
export const toggleFeedbackModal = createAction('TOGGLE_FEEDBACK_MODAL');
