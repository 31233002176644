export const initialState = {
  payments: {
    data: null,

    pager: {
      pageSize: null,
      page: null,
      pages: null,
      total: null,
    },

    isLoading: false,
    isLoaded: false,
    isError: false,
  },
  paymentId: null,
  isPaymentModalOpen: false,
};
