import React from "react";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Logout from "../Logout/Logout";
import { toggleModal } from "../../actions/feedbackModal";
import s from "./header.module.scss";

const MaillingHeader = ({ rector, location }) => {
  const active = `${s.link} ${s.selected}`;
  const unActive = `${s.link}`;

  return (
    <div className={s.wrapper}>
      <div className={s.linksBlock}>
        {!rector && (
          <div className={location.pathname === "/mailing" ? active : unActive}>
            <NavLink to="/mailing">Рассылка</NavLink>
          </div>
        )}
        {!rector && (
          <div
            className={
              location.pathname === "/mail-history" ? active : unActive
            }
          >
            <NavLink to="/mail-history">История рассылок</NavLink>
          </div>
        )}
        {!rector && (
          <div className={location.pathname === "/groups" ? active : unActive}>
            <NavLink to="/groups">Функциональные группы</NavLink>
          </div>
        )}
      </div>

      <div className={s.actions}>
        <Logout />
      </div>

    </div>
  );
};

const mapDispatch = { toggleModal };

export default compose(withRouter, connect(null, mapDispatch))(MaillingHeader);
