import { handleActions } from "redux-actions";
import { initialState } from "../../store/user";
import {
  loginOnChange,
  passwordOnChange,
  addProfile,
  setError,
  clearErrorMessage,
  changeLocalisationMode,
  deleteLoginPassword,
  idNumOnChange,
  newPasswordOnChange,
  confirmPasswordOnChange
} from "../../actions/authorisation";

const authorisationReducer = handleActions({

  [loginOnChange.toString()]: (state, { payload: login }) => {
    return {...state, login};
  },

  [idNumOnChange.toString()]: (state, { payload: idNum }) => {
    return {...state, idNum};
  },

  [passwordOnChange.toString()]: (state, { payload: password }) => {
    return {...state, password};
  },

  [newPasswordOnChange.toString()]: (state, { payload: newPassword }) => {
    return {...state, newPassword};
  },

  [confirmPasswordOnChange.toString()]: (state, { payload: confirmPassword }) => {
    return {...state, confirmPassword};
  },

  [deleteLoginPassword.toString()]: ( state ) => {
    return {...state, login: '', password: ''};
  },

  [addProfile.toString()]: (state, { payload: profile }) => {
    return {...state, profile };
  },

  [setError.toString()]: (state, { payload: errorMessage }) => {
    return {...state, error: errorMessage};
  },

  [clearErrorMessage.toString()]: ( state ) => {
    return {...state, error: null};
  },

  [changeLocalisationMode.toString()]: (state, { payload: bool }) => {
    return {...state, localisationMode: bool};
  },

}, initialState);

export default authorisationReducer;
