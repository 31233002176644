import { handleActions } from "redux-actions";
import { initialState } from "../../store/payment";
import {
  reloadPaymentsRequest,
  reloadPaymentsFailure,
  reloadPaymentsSuccess,
  paymentsRequest,
  paymentsFailure,
  paymentsSuccess,
  setPayment,
  openPaymentModal,
  closePaymentModal,
} from "../../actions/payment";

const payment = handleActions(
  {
    [openPaymentModal.toString()]: (state) => {
      return {
        ...state,
        isPaymentModalOpen: true,
      };
    },
    [closePaymentModal.toString()]: (state) => {
      return {
        ...state,
        isPaymentModalOpen: false,
      };
    },

    [reloadPaymentsRequest.toString()]: (state) => {
      try {
        return {
          ...state,

          payments: {
            isLoading: true,
          },
        };
      } catch (error) {
        console.error(
          `Error while handle ${reloadPaymentsRequest.toString()} reducer`,
          error
        );

        return state;
      }
    },
    [reloadPaymentsSuccess.toString()]: (state) => {
      try {
        return {
          ...state,

          payments: {
            isLoading: false,
          },
        };
      } catch (error) {
        console.error(
          `Error while handle ${reloadPaymentsSuccess.toString()} reducer`,
          error
        );

        return state;
      }
    },
    [reloadPaymentsFailure.toString()]: (state) => {
      try {
        return {
          ...state,

          payments: {
            isLoading: false,
          },
        };
      } catch (error) {
        console.error(
          `Error while handle ${reloadPaymentsFailure.toString()} reducer`,
          error
        );

        return state;
      }
    },

    [paymentsRequest.toString()]: (state) => {
      try {
        return {
          ...state,

          payments: {
            ...state.payments,
            isLoading: true,
          },
        };
      } catch (error) {
        console.error(
          `Error while handle ${paymentsRequest.toString()} reducer`,
          error
        );

        return state;
      }
    },

    [paymentsSuccess.toString()]: (state, action) => {
      try {
        const { pager, data, statistic } = action.payload;

        return {
          ...state,

          payments: {
            ...state.payments,

            data,
            pager,
            statistic,

            isLoading: false,
            isLoaded: true,
          },
        };
      } catch (error) {
        console.error(
          `Error while handle ${paymentsSuccess.toString()} reducer`,
          error
        );

        return state;
      }
    },

    [paymentsFailure.toString()]: (state) => {
      try {
        return {
          ...state,

          payments: {
            ...state.payments,

            data: null,
            pager: initialState.payments.pager,

            isLoading: false,
            isError: true,
          },
        };
      } catch (error) {
        console.error(
          `Error while handle ${paymentsFailure.toString()} reducer`,
          error
        );

        return state;
      }
    },

    [setPayment.toString()]: (state, action) => {
      try {
        return {
          ...state,

          paymentId: action.payload.paymentId,
        };
      } catch (error) {
        console.error(
          `Error while handle ${setPayment.toString()} reducer`,
          error
        );

        return state;
      }
    },
  },
  initialState
);

export default payment;
