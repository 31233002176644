import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { isEqual } from 'lodash';
import qs from 'qs';

import history from '../../history';
import { ROUTES } from '../../constants';
import axios from '../../api';
import CheckBoxComponent from '../../components/CheckBoxComponent/CheckBoxComponent';
import { getProfile, loginRowguid } from '../../actions/authorisation';
import {
  getMessages,
  isCleanRequared,
  cleanMessages,
  getCurrentMessage,
  setUnreadMessages
} from '../../actions/mail';
import {
  firstNameSelector,
  lastNameSelector,
  groupSelector
} from '../../selectors/userSelectors';
import {
  hasMoreSelector,
  currentMessageSelector,
  isUnreadMessages
} from '../../selectors/mailSelectors';
import List from '../../components/MessagesList/MessagesList';
import s from './messages.module.scss';
import headerStyles from '../../components/Headers/header.module.scss';
import Header from '../../components/Headers/MessagesHeader';

class Messages extends React.Component {
  state = {
    linkToJournal: ''
  };

  componentDidMount() {
    const {
      location: { pathname, search }
    } = this.props;
    const queryParams = qs.parse(search.replace('?', ''));

    if (this.props.user) {
      this.props.cleanMessages();
      this.props.getMessages({ next: false });
    } else {
      const key = pathname.split('/')[1];
      if (!localStorage.getItem(ROUTES[key]) && !queryParams.usr) {
        (pathname === '/rector-mail-history' ||
          pathname === '/mail-history' ||
          pathname === '/mailing') &&
          history.push('/login/employee');
        pathname === '/messages' && history.push('/login/student');
      } else {
        if (queryParams.usr) {
          this.props.loginRowguid({ rowguid: queryParams.usr });
        } else {
          this.props.getProfile({
            token: localStorage.getItem(ROUTES[key]),
            pathname: key,
            redirect: pathname
          });
        }
      }
    }
  }

  getLink = () => {
   axios.get(`/api/journal`).then((response) => {
      this.setState({ linkToJournal: response.data.url });
    });
  };

  componentWillReceiveProps({ user }) {
    if (!isEqual(user, this.props.user) && user) {
      this.props.getMessages({ next: false });
      this.getLink();
    }
  }

  getUnread = (bool) => {
    this.props.cleanMessages();
    this.props.setUnreadMessages(bool);
  };

  render() {
    const checkboxText = <FormattedMessage id="checkUnreadMessages" />;
    return (
      <div className={s.wrapper}>
        <div className={s.content}>
          <Header linkToJournal={this.state.linkToJournal} headWrapper={headerStyles.wrapper2} />
          <div className={s.head}>
            <div className={s.title}>
              {`${this.props.lastName || ''} ${this.props.firstName ||
                ''}, ${this.props.group || ''}`}
              <div className={s.checkboxWrapper}>
                <CheckBoxComponent
                  checked={this.props.isUnreadMessages}
                  callback={this.getUnread.bind(this)}
                  text={checkboxText}
                />
              </div>
            </div>
          </div>
          <div className={s.messagesList}>
            <List
              hasMore={this.props.hasMore}
              getMessages={this.props.getMessages}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.profile,
    firstName: firstNameSelector(state),
    lastName: lastNameSelector(state),
    group: groupSelector(state),
    hasMore: hasMoreSelector(state),
    currentMessage: currentMessageSelector(state),
    isUnreadMessages: isUnreadMessages(state)
  };
};

const mapDispatchToProps = {
  getMessages,
  getProfile,
  isCleanRequared,
  cleanMessages,
  getCurrentMessage,
  setUnreadMessages,
  loginRowguid
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Messages);
