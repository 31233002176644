import {createAction} from "redux-actions";
import { constants } from "../authorisation/constants";

export const getMessages = createAction(constants.getMessages);
export const setMessages = createAction(constants.setMessages);
export const changeReadMode = createAction(constants.setMessages);
export const isCleanRequared = createAction (constants.isCleanRequared);
export const cleanMessages = createAction (constants.cleanMessages);
export const setCurrentMessage = createAction (constants.setCurrentMessage);
export const getCurrentMessage = createAction (constants.getCurrentMessage);
export const updateMessage = createAction(constants.updateMessage);
export const setUnreadMessages = createAction(constants.setUnreadMessages);
export const getUnreadMessages = createAction (constants.getUnreadMessages);

