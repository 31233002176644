import {createAction} from "redux-actions";
import { constants } from "./constants"

export const loginOnChange = createAction(constants.loginOnChange);
export const passwordOnChange = createAction(constants.passwordOnChange);
export const idNumOnChange = createAction(constants.idNumOnChange);
export const newPasswordOnChange = createAction(constants.newPasswordOnChange);
export const confirmPasswordOnChange = createAction(constants.confirmPasswordOnChange);
export const restorePassword = createAction(constants.restorePassword);
export const changePassword = createAction(constants.changePassword);
export const login = createAction(constants.login);
export const loginRowguid = createAction('LOGIN_ROWGUID');
export const addProfile = createAction(constants.addProfile);
export const setError = createAction(constants.setError);
export const clearErrorMessage = createAction(constants.clearErrorMessage);
export const loginEmployee = createAction(constants.loginEmployee);
export const getProfile = createAction(constants.getProfile);
export const logout = createAction(constants.logout);
export const changeLocalisationMode = createAction(constants.changeLocalisationMode);
export const deleteLoginPassword = createAction('DELETE_LOGIN_AND_PASSWORD');
