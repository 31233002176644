import { takeEvery } from "redux-saga/effects";
import { constants } from "../actions/authorisation/constants";
import { constants as feedbackConstants } from "../actions/feedbackModal/constants";
import {loginSaga, getProfileSaga, logout, loginRowguid, restorePasswordSaga, changePasswordSaga} from "./userSagas";
import {
  messagesSaga,
  getCurrentMessage,
  updateMessage,
  setUnreadMessages,
} from "./mailSagas";
import {
  fetchCourse,
  fetchStudents,
  sendMessage,
  fetchGroups,
  uploadFile,
  fetchFaculty,
  addNewGroup,
  fetchAllGroups,
  deleteFunctionalGroup,
  addStudentsToGroup,
  editFunctionalGroup,
  fetchEmployee,
  fetchSchedule,
} from "./saga";
import { sendFeedbackSaga } from "./feedbackSagas";
import { fetchPayments } from "./payment";
import { constants as paymentConstants } from "../actions/payment/constants";
import {
  createGroupStart,
  fetchFunctionalGroups,
  destroyFunctionalGroup,
  addStudentsToFunctionalGroup,
  updateFunctionalGroup,
} from "../actions";

export function* watcherSaga() {
  yield takeEvery("LOGIN_ROWGUID", loginRowguid);
  yield takeEvery(constants.logout, logout);
  yield takeEvery(constants.login, loginSaga);
  yield takeEvery(constants.restorePassword, restorePasswordSaga);
  yield takeEvery(constants.changePassword, changePasswordSaga);
  yield takeEvery(constants.getMessages, messagesSaga);
  yield takeEvery(constants.getProfile, getProfileSaga);
  yield takeEvery(constants.getCurrentMessage, getCurrentMessage);
  yield takeEvery(constants.updateMessage, updateMessage);
  yield takeEvery(constants.setUnreadMessages, setUnreadMessages);
  yield takeEvery("FETCH_COURSE", fetchCourse);
  yield takeEvery("FETCH_STUDENTS", fetchStudents);
  yield takeEvery("SEND_MESSAGE", sendMessage);
  yield takeEvery("FETCH_GROUPS", fetchGroups);
  yield takeEvery("UPLOAD_FILE", uploadFile);
  yield takeEvery("FETCH_FACULTY", fetchFaculty);
  yield takeEvery("FETCH_EMPLOYEE", fetchEmployee);
  yield takeEvery(createGroupStart().type, addNewGroup);
  yield takeEvery(fetchFunctionalGroups().type, fetchAllGroups);
  yield takeEvery(destroyFunctionalGroup().type, deleteFunctionalGroup);
  yield takeEvery(addStudentsToFunctionalGroup().type, addStudentsToGroup);
  yield takeEvery(updateFunctionalGroup().type, editFunctionalGroup);
  yield takeEvery("FETCH_SCHEDULE", fetchSchedule);
  yield takeEvery(feedbackConstants.sendFeedbackRequest, sendFeedbackSaga);
  yield takeEvery(paymentConstants.paymentsRequest, fetchPayments);
}
