import React from 'react';
import s from './roundCheckbox.module.scss'

const RoundCheckbox = ({id, number, addGroup}) => {
  const stringNumber = String(number)
  return (
    <div className={s.container}>
      <div className={s.round}>
        <input type="checkbox" id={id} 
          onClick={(e) => {
          addGroup(stringNumber, e.currentTarget.checked)}}/>
        <label htmlFor={id} >{number}</label>
      </div>
    </div>
  );
};

export default RoundCheckbox;
