import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Icon, Modal, Input, Button, Form } from 'antd';

import { createGroupStart } from '../../actions';
import s from './index.module.scss';

class AddNewGroup extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      this.props.createGroupStart({ params: values });
    });
  }

  render() {
    const { form: { getFieldDecorator } } = this.props;

    return (
      <Modal
        centered
        visible={this.props.showModal}
        onCancel={this.props.onCancel}
        closeIcon={<Icon type="close-circle" style={{ fontSize: '20px' }}/>}
        footer={null}
      >
        <div className={s.modalContent}>
          <div className={s.modalTitle}>
            Создание новой функциональной группы
          </div>
          <div className={s.modalForm}>
            <Form onSubmit={this.handleSubmit}>
              <div className={s.modalFormLabel}>
                Наименование группы
              </div>
              {getFieldDecorator('title', {
                initialValue: ''
              })(
                <div className='sendForm__inputContainer'>
                  <Input placeholder='Введите название группы, до 50 символов' />
                </div>
              )}
                <div className={s.modalFormLabel}>
                  Описание группы
                </div>
              {getFieldDecorator('description', {
                initialValue: ''
              })(
                <div className='sendForm__textareaContainer'>
                  <Input.TextArea placeholder="Введите любое описание, до 200 символов" rows={8} ></Input.TextArea>
                </div>
              )}
              <div className={s.buttonContainer}>
                <div className='buttonWrapper'>
                  <Button type="primary" shape="round" size='large' htmlType="submit">
                    Добавить
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  createGroupStart,
};

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)((Form.create()(AddNewGroup)));
