import { createAction } from "redux-actions";
import { constants } from "./constants";

export const paymentsRequest = createAction(constants.paymentsRequest);
export const paymentsSuccess = createAction(constants.paymentsSuccess);
export const paymentsFailure = createAction(constants.paymentsFailure);

export const reloadPaymentsRequest = createAction(
  constants.reloadPaymentsRequest
);
export const reloadPaymentsSuccess = createAction(
  constants.reloadPaymentsSuccess
);
export const reloadPaymentsFailure = createAction(
  constants.reloadPaymentsFailure
);

export const setPayment = createAction(constants.setPayment);

export const openPaymentModal = createAction(constants.openPaymentModal);
export const closePaymentModal = createAction(constants.closePaymentModal);
