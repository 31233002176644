export const constants = {
  loginOnChange: 'user/loginOnChange',
  passwordOnChange: 'user/passwordOnChange',
  idNumOnChange: 'user/idNumOnChange',
  newPasswordOnChange: 'user/newPasswordOnChange',
  confirmPasswordOnChange: 'user/confirmPasswordOnChange',
  restorePassword: 'user/restorePassword',
  changePassword: 'user/changePassword',
  login: 'user/login',
  addProfile: 'user/addProfile',
  setError: 'user/setError',
  clearErrorMessage: 'user/clearErrorMessage',
  loginEmployee: 'user/loginEmployee',
  getProfile: 'user/getProfile',
  logout: 'user/logut',
  changeLocalisationMode: 'user/changeLocalisationMode',

  getMessages: 'mail/getMessages',
  setMessages: 'mail/setMessages',
  changeReadMode: 'mail/changeReadMode',
  isCleanRequared: 'mail/isCleanRequared',
  cleanMessages: 'mail/cleanMessages',
  setCurrentMessage: 'mail/setCurrentMessage',
  getCurrentMessage: 'mail/getCurrentMessage',
  updateMessage: 'mail/updateMessage',
  setUnreadMessages: 'mail/setUnreadMessages',
  getUnreadMessages: 'mail/getUnreadMessages'
};
