import React from 'react';
import { Button, Input, Alert, Icon, Modal } from 'antd';
import { connect } from "react-redux";
import { isEmpty, isNumber } from 'lodash';

import { ERRORS } from '../../constants';
import Attachment from '../../images/attachment.svg';
import s from './sendForm.module.scss';
import { sendMessage } from '../../actions';
import { facultySelector } from '../../selectors';
import './sendForm.scss';

const File =  React.createRef();

class SendForm extends React.Component {
  state = {
    title: '',
    message: '',
    path: '',
    alert: false,
    error: [],
  }

  componentDidMount() {
    const inputFiles = document.getElementById('input-file');
    inputFiles.addEventListener('change', (e) => {
      this.setState({ path: e.target.value.split(/\\/)[2] });
    });
  }

  callBack = () => Modal.success({
    content: 'Ваше сообщение отправлено успешно!',
  });

  error = (data) => Modal.error({
    content: data.errors.map(e => <>{ERRORS[e.property || e] || 'Ошибка отправки сообщения'}<br/></>),
  });

  handleChange = (e, type) => this.setState({ [type]: e.currentTarget.value });

  typeValue = () => {
    const { filters, functionalGroups, faculties } = this.props;

    if (filters.allStudents && filters.allChecked) {
      return {type: 'all', value: 'весь БГМУ'}
    } else {
      if (
        !filters.allChecked || (filters.name || filters.is_captain || (filters.group.length < 2 && !isEmpty(filters.group)))
      ) {
        return {type: 'group', value: ''}
      } else if (isNumber(filters.functionalGroup)) {
        const name = functionalGroups.find(g => g.id === filters.functionalGroup).title
        return {type: 'group', value: name}
      } else if (!isEmpty(filters.group)) {
        return {type: 'group', value: filters.group.join(',')}
      } else if (filters.course) {
        return {type: 'course', value: String(filters.course)}
      } else if (isNumber(filters.faculty)) {
        const name = faculties.find(g => g.id === filters.faculty).name
        return {type: 'group', value: name}
      }
    }
  }

  handleUpload = () => {
    const { checkedStudents: students } = this.props;
    const { title, message } = this.state;
    const file = document.getElementById('input-file').files[0];
    const types = this.typeValue();
    const params = { ...types, title, message, students, callBack: this.callBack, error: this.error };
    if (file) {
      const formData = new FormData();
      formData.append('file', document.getElementById('input-file').files[0]);
      this.props.sendMessage({ ...params, file: formData });
    } else {
      this.props.sendMessage({ ...params });
    }
  }

  success = () => Modal.success({
    content: 'Ваше сообщение отправлено успешно!',
  });

  render() {
    const { title, message, path, alert, error } = this.state;
    const errorMessages = error.map(e => <>{ERRORS[e.property || e]}<br/></>);
    return (
      <div className={s.wrapper}>
        <form>
          <div className='sendForm__inputContainer'>
            <Input value={this.state.title} onChange={(e) => this.handleChange(e, 'title')} placeholder='тема сообшения' />
          </div>
          <div className='sendForm__textareaContainer'>
            <input ref={File} className={s.file} type="file" id="input-file"/>
            <img className={s.icon} src={Attachment} alt='add file' onClick={() => File.current.click()} />
            <Input.TextArea value={this.state.message} onChange={(e) => this.handleChange(e, 'message')} placeholder="Текст сообщения" rows={8} ></Input.TextArea>
            {path && <div className={s.path}>{path}<Icon onClick={() => {
              File.current.value = '';
              this.setState({path: ''})}} type="close" style={{ color: 'gray' }} /></div>}
          </div>
          <div className={s.buttonContainer}>
            <div className='buttonWrapper'>
              <Button
                disabled={isEmpty(title) || (isEmpty(message) && isEmpty(path)) || isEmpty(this.props.checkedStudents) || this.props.isLoading}
                onClick={() => this.handleUpload()}
                placeholder="Текст сообщения"
                type="primary"
                shape="round"
                size='large'
                loading={this.props.isLoading}
              >
                отправить
              </Button>
            </div>
          </div>
          {alert && <Alert
            style = {{position: 'absolute', width: '250px', zIndex: '999'}}
            message="Сообщения успешно отправлены выбранным студентам"
            type="success"
            closable
            onClose={() => this.setState({ alert: false })}
          />}
          {!isEmpty(error) && <Alert
            style = {{position: 'absolute', width: '250px', zIndex: '999'}}
            message={errorMessages}
            type="error"
            closable
            onClose={() => this.setState({ error: [] })}
          />}
        </form>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    faculties: facultySelector(state),
    isLoading: state.mail.isSending,
    functionalGroups: state.root.functionalGroups.items
  };
};

export default connect(mapStateToProps, { sendMessage })(SendForm);
