import React from 'react';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '../constants';
import history from "../history";

export const authorisationHoc = (Component) => {

  class RedirectComponent extends React.Component {
    componentDidMount() {
      const key = this.props.location.pathname.split('/')[1];
      if (!localStorage.getItem(ROUTES[key])) {
        (this.props.location.pathname === '/rector-mail-history' || this.props.location.pathname === '/mail-history' || this.props.location.pathname === '/mailing' || this.props.location.pathname === '/groups') && history.push('/login/employee');
        (this.props.location.pathname === '/messages' || this.props.location.pathname === '/schedule') && history.push('/login/student');
      }
    }

    render() {
      return <Component {...this.props} />
    }
  }

  return withRouter(RedirectComponent);
};
