import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Icon, Modal, Button, Form, Select } from 'antd';
import { studentsSelector } from '../../selectors';
// import { get } from 'lodash';

import { addStudentsToFunctionalGroup } from '../../actions';
import s from './index.module.scss';

class AddStudentsToGroup extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      this.props.addStudentsToFunctionalGroup({ id: this.props.functionalGroup, params: { students: this.props.checkedStudents } });
      this.props.onCancel();
    });
  }

  render() {
    const { form: { getFieldDecorator }, displayGroupsOptions, checkedStudents, students } = this.props;
    const displayStudents = students.filter(student => checkedStudents.includes(student.id));
    return (
      <Modal
        centered
        visible={this.props.showModal}
        onCancel={this.props.onCancel}
        closeIcon={<Icon type="close-circle" style={{ fontSize: '20px' }}/>}
        footer={null}
        width={700}
      >
        <div className={s.modalContent}>
          <div className={s.modalTitle}>
            Добавление студента в функциональную группу
          </div>
          <div className={s.modalForm}>
            <Form onSubmit={this.handleSubmit}>
              <div className={s.modalFormLabel}>
                Выбранная функциональная группа
              </div>
              {getFieldDecorator('group_id', {
              })(
                <div className='selectContainer'>
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Название функциональной группы"
                    optionFilterProp="children"
                    onChange={this.props.handleChangeGroup}
                    value={this.props.functionalGroup}
                  >
                    {displayGroupsOptions}
                  </Select>
                </div>
              )}
              <div className={s.modalFormLabel}>
                Выбранные студенты
              </div>
              <div className={s.sudentsContent}>
                {displayStudents.map((student, index) =>
                  <div className={s.sudentsWrapper}>
                    <label>
                      <span>{`${index + 1}. ${student.last_name} ${student.first_name} ${student.mid_name}`}</span>
                    </label>
                  </div>
                )}
              </div>
              <div className={s.buttonContainer}>
                <div className='buttonWrapper'>
                  <Button type="primary" shape="round" size='large' htmlType="submit">
                    Добавить
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  addStudentsToFunctionalGroup
};

const mapStateToProps = state => ({
  students: studentsSelector(state),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)((Form.create()(AddStudentsToGroup)));
