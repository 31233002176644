import React from 'react';
import { Radio } from 'antd';
import "antd/dist/antd.css";

const RadioButton = ({ checked }) => {
  return (
    <div className='radio'>
      <Radio checked={checked}/>
    </div>
  );
};

export default RadioButton;

