import { put, select } from 'redux-saga/effects' ;
import { setMessages, setCurrentMessage, getMessages, getUnreadMessages } from '../actions/mail';
import axios from '../api';

import { buildUrlParams } from '../api';

export function* messagesSaga({ payload: { next = false, faculty, employee_id}}) {
  try {
    const mail = yield select(state => state.mail);
    const params = buildUrlParams({ unread: mail.unread, page: mail.currentPage, faculty, employee_id });
    const response = yield axios.get(`api/message?${params}`);
    yield put(setMessages({ messages: response.data, next }));
  } catch (error) {

  }
}

export function* setUnreadMessages(payload) {
  yield put(getUnreadMessages(payload.payload));
  yield put(getMessages({ next: false }));
}

export function* getCurrentMessage(payload) {
  try {
    const response = yield axios.get(`api/message/${payload.payload}`);
    yield put(setCurrentMessage(response.data));
  } catch (error) {

  }
}

export function* updateMessage(payload) {
  try {
    yield axios.put(`api/message/${payload.payload}`, { unread: true });
  } catch (error) {
  }
}
