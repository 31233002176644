import React from 'react';
import { compose } from "redux";
import { isEmpty } from 'lodash';
import { withRouter } from "react-router-dom";
import { Button } from 'antd';
import InputComponent from '../../components/InputComponent/InputComponent';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { IntlContext } from "../../i18n/IntlContext";
import { FormattedMessage } from "react-intl";
import s from './restore-password.module.scss';
import {Link} from "react-router-dom";
import {
  restorePassword,
  loginOnChange,
  idNumOnChange
} from "../../actions/authorisation";
import { useDispatch, useSelector} from "react-redux";

const RestorePassword = () => {
  const dispatch = useDispatch();

  const {login, idNum, error} = useSelector(state => state.user);

  const { locale } = React.useContext(IntlContext);

  const changeLogin = e => dispatch(loginOnChange(e.currentTarget.value));
  const changeIdNum = e => dispatch(idNumOnChange(e.currentTarget.value));

  return (
      <div className={s.wrapper}>
      <span className={s.title}>
        <FormattedMessage id="passwordRecovery" />
      </span>
        <div className={s.content}>
          <div className={s.loginContainer}>
            <InputComponent
              placeholder={'Diego Sanchez'}
              label={<FormattedMessage id="login" />}
              inputCallback={changeLogin}
              inputValue={login}
            />
          </div>
          <InputComponent
            label={<FormattedMessage id="idNum" />}
            inputValue={idNum}
            inputCallback={changeIdNum}
          />

          <div className={s.buttonsContainer}>
            <Button disabled={isEmpty(login) || isEmpty(idNum)} type="primary" shape="round" size='large' onClick={() => {
              dispatch(restorePassword({pathname: "student", login: login, student_card_number: idNum, language: locale}))}
            }>
              <span className={s.buttonText}><FormattedMessage id="passwordRecovery" /></span>
            </Button>
          </div>

          <div className={s.buttonsContainer}>
            <Link to="/login/student"><FormattedMessage id="authorisationButton" /></Link>
          </div>

          { error && <div className = {s.errorContainer}>
            <ErrorMessage />
          </div>}
        </div>
      </div>
  );
};

export default compose(
    withRouter
)(RestorePassword);