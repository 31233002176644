import { handleActions } from "redux-actions";
import { initialState } from "../../store/feedbackModal";
import {
  toggleModal,
  sendFeedbackRequest,
  sendFeedbackSuccess,
  sendFeedbackFailure,
} from "../../actions/feedbackModal";

const feedbackReducer = handleActions(
  {
    [toggleModal.toString()]: (state) => {
      return { ...state, isOpen: !state.isOpen };
    },

    [sendFeedbackRequest.toString()]: (state) => {
      return { ...state, isLoading: true };
    },
    [sendFeedbackSuccess.toString()]: (state) => {
      return { ...state, isLoading: false };
    },
    [sendFeedbackFailure.toString()]: (state) => {
      return { ...state, isLoading: false };
    },
  },
  initialState
);

export default feedbackReducer;
