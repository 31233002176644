import React from 'react';
import { connect } from "react-redux";

import { groupsSelector } from '../../selectors';
import RoundCheckbox from '../RoundCheckbox/RoundCheckbox';
import s from './groupsList.module.scss';
import './groupsList.scss';

const GroupsList = ({ groups, fetchStudents, currentCroup, course = null, addGroup }) => {
  return (
    course && <>
      <div className={s.text}>выберите № группы</div>
      <div className = {s.groupsContainer}>
        <div className = {s.groupsBlock}>
          {groups.map(group =>
            <div key={group.id} className="group" >
              <RoundCheckbox id={group.id} number={group.name} addGroup = {addGroup}/>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  groups: groupsSelector(state)
});

export default connect(mapStateToProps, null)(GroupsList);
