import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Icon, Modal, Input, Button, Form, Checkbox } from 'antd';
import { get } from 'lodash';

import CheckBoxComponent from '../../components/CheckBoxComponent/CheckBoxComponent';
import { updateFunctionalGroup } from '../../actions';
import s from './index.module.scss';

const InputComponent = ({ value, placeholder, onChange }) => {
  return <div className='sendForm__inputContainer'>
    <Input placeholder={placeholder} value={value} onChange={onChange} />
  </div>;
}

class EditGroup extends React.Component {
  state = { checkedAll: true }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      this.props.updateFunctionalGroup({
        id: get(this.props, 'editGroup.id'),
        params: {
          title: values.title,
          description: get(this.props, 'editGroup.description'),
          students: values.students
        }
      });
    });
  }

  handleChange = value => {
    this.setState({ checkedAll: value }, () => {
      if (this.state.checkedAll) {
        this.props.form.setFieldsValue({ students: get(this.props, 'editGroup.students', []).map((student, index) => student.id) });
      } else {
        this.props.form.setFieldsValue({ students: [] });
      }
    });
  }

  render() {
    const { form: { getFieldDecorator }, editGroup } = this.props;

    return (
      <Modal
        centered
        visible={this.props.showModal}
        onCancel={this.props.onCancel}
        closeIcon={<Icon type="close-circle" style={{ fontSize: '20px' }}/>}
        footer={null}
        width={700}
      >
        <div className={s.modalContent}>
          <div className={s.modalTitle}>
            Управление функциональной группой
          </div>
          <div className={s.modalForm}>
            <Form onSubmit={this.handleSubmit}>
              <div className={s.modalFormLabel}>
                Наименование группы
              </div>
              {getFieldDecorator('title', {
                initialValue: get(editGroup, 'title', '')
              })(
                <InputComponent placeholder='Наименование' />
              )}
              <div className={s.modalFormLabel}>
                Группу создал
              </div>
              {getFieldDecorator('owner', {
                initialValue: get(editGroup, 'employee.full_name', '')
              })(
                <InputComponent placeholder='ФИО' />
              )}
              <div className={s.studentsLabelWrapper}>
                <span>Список студентов данной группы (Всего {get(editGroup, 'students', []).length} чел)</span>
                <div className={s.rightCheckbox}>
                  <label>
                    <span className={s.rightCheckbox_text}>отметить всех</span>
                    <CheckBoxComponent callback={this.handleChange} checked={this.state.checkedAll} />
                  </label>
                </div>
              </div>
              {getFieldDecorator('students', {
                initialValue: get(editGroup, 'students', []).map((student, index) => student.id),
              })(
                <Checkbox.Group>
                  <div className={s.sudentsContent}>
                    {get(editGroup, 'students', []).map((student, index) =>
                      <div className={s.sudentsWrapper}>
                        <label>
                          <span>{`${index + 1}. ${student.last_name} ${student.first_name} ${student.mid_name}`}</span>
                          <Checkbox value={student.id}></Checkbox>
                        </label>
                      </div>
                    )}
                  </div>
                </Checkbox.Group>
              )}
              <div className={s.buttonContainer}>
                <div className='buttonWrapper'>
                  <Button type="primary" shape="round" size='large' htmlType="submit">
                    Сохранить
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  updateFunctionalGroup,
};

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)((Form.create()(EditGroup)));
