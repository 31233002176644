import React from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { Button, Icon } from 'antd';
import { get, isEmpty, last } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { compose } from "redux";
import { FormattedMessage } from "react-intl";

import { ICONS } from '../../constants';
import { authorisationHoc } from '../../hoc/authorusationHoc';
import { uploadFile } from '../../actions';
import s from './messagesTable.module.scss';

class MessagesTable extends React.Component {
	state = {
		open: [],
	}

	destination = (type, value, students) => {
		if (type === 'course') {
			return `Курс №${value}`;
		} else if (type === 'group' && value.split(',').length > 1) {
			return value.split(',').map(v => <>Группа №{v}<br/></>);
		} else if (type === 'group' && isEmpty(value)) {
			return students.map(student => <>{student.student.last_name} {student.student.first_name} {student.student.mid_name} {student.is_read && ' -  Прочитано'}<br/></>)
		} else if (type === 'group' && !isEmpty(value)) {
			return value
		} else if (type === 'all') {
			return 'Все студенты'
		}
	}

	displayMessage = (text, id) => {
		const { open } = this.state;
		if (text.length > 500 && !open.includes(id)) {
			return <div>
				{text.substr(0, 500)}
				<div className={s.close} onClick={() => this.setState(state => ({ open: state.open.concat(id) }))}>...</div>
			</div>
		} else {
			return text;
		}
	}

	render() {
		const { messages, hasMore, getMessages, faculty, is_admin, employee_id } = this.props;
		return (
			<div className={s.table}>
				<div className={s.wrapper}>
					<div className={s.head}>
						<div className={s.firstBlock}>
							Текст рассылки
						</div>
						<div className={s.secondBlock}>
							Дата
						</div>
						<div className={s.thirdBlock}>
							Получатели
						</div>
						{is_admin && <div className={s.thirdBlock}>
							Факультет
						</div>}
						<div className={s.fourthBlock}>
							Автор рассылки
						</div>
					</div>
					{
					messages && messages.map(message => (
						<>
							<div key = { message.id } className={s.message}>
								<div className={s.firstBlock}>
									<div className={s.messageTitle}>
										{ message.title }
									</div>
									<div className = {s.text}>
										{this.displayMessage(message.message, message.id) }
									</div>
								</div>
								<div className={s.secondBlock}>
									{moment(message.created_at).format('DD MMMM YYYY')}
								</div>
								<div className={s.thirdBlock}>
									{this.destination(message.message_type, message.message_type_value, message.students)}
								</div>
								{is_admin && <div className={s.thirdBlock}>
									{message.message_type === 'all' ? 'весь БГМУ' : message.message_type_value }
								</div>}
								<div className={s.fourthBlock}>
								{`${message.employee.full_name}`}
								</div>
							</div>
							{!isEmpty(get(message, 'files', [])) && <div className={s.fileWrapper}>
						<div className={s.file} onClick={() => this.props.uploadFile(get(message, 'files')[0].id)}>
              <img className={s.document} alt='doc' src={ICONS[last(get(message, 'files')[0].file_name.split('.'))]} />
              <span>{get(message, 'files')[0].file_name}</span>
            </div>
            <span>
              <Icon type="close" style={{ color: 'gray' }} />
            </span>
          </div>}
							<div className={s.border}></div>
							</>
						) )
					}
				</div>
				<div className = { s.loadMore }>{!isEmpty(messages) && hasMore && <Button  onClick = {() => getMessages({next: true, faculty, employee_id}) } ><FormattedMessage id="loadingMore" /></Button> }</div>

			</div>
		);
	};
};

export default compose(
  withRouter,
  connect(null, { uploadFile })
)(authorisationHoc(MessagesTable));
