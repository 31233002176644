import React from 'react';
import { FormattedMessage } from "react-intl";
import LogoutImg from '../../images/logout.svg'
import { connect } from "react-redux";
import { logout } from '../../actions/authorisation';
import { withRouter } from 'react-router-dom';
import s from './logout.module.scss';

const Logout = ({ logout, location: { pathname } }) => {
  return (
    <div className={s.logout} onClick={() => logout({ pathname: pathname.split('/')[1] })}>
      <FormattedMessage id="logout" />
      <img className={s.icon} src={LogoutImg} alt='logout' />
    </div>
  );
};

const mapDispatchToProps = {
  logout
};

export default withRouter(connect(null, mapDispatchToProps)(Logout));
