import React from 'react';
import { compose } from "redux";
import { isEmpty } from 'lodash';
import { withRouter } from "react-router-dom";
import { Button } from 'antd';
import InputComponent from '../../components/InputComponent/InputComponent';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { IntlContext } from "../../i18n/IntlContext";
import { FormattedMessage } from "react-intl";
import s from './change-password.module.scss';
import {Link} from "react-router-dom";
import {
  changePassword,
  loginOnChange,
  passwordOnChange,
  newPasswordOnChange,
  confirmPasswordOnChange
} from "../../actions/authorisation";
import { useDispatch, useSelector} from "react-redux";

const ChangePassword = () => {
  const dispatch = useDispatch();

  const { login, password, newPassword, confirmPassword, error } = useSelector(state => state.user);

  const { locale } = React.useContext(IntlContext);

  const changeLogin = e => dispatch(loginOnChange(e.currentTarget.value));
  const changeOldPassword = e => dispatch(passwordOnChange(e.currentTarget.value));
  const changeNewPassword = e => dispatch(newPasswordOnChange(e.currentTarget.value));
  const changeConfirmPassword = e => dispatch(confirmPasswordOnChange(e.currentTarget.value));

  return (
    <div className={s.wrapper}>
    <span className={s.title}>
      <FormattedMessage id="changePassword" />
    </span>
      <div className={s.content}>
        <div className={s.loginContainer}>
          <InputComponent
            placeholder={'Diego Sanchez'}
            label={<FormattedMessage id="login" />}
            inputCallback={changeLogin}
            inputValue={login}
          />
        </div>
        <div className={s.loginContainer}>
          <InputComponent
            type={'password'}
            label={<FormattedMessage id="oldPassword" />}
            inputValue={password}
            inputCallback={changeOldPassword}
          />
        </div>
        <div className={s.loginContainer}>
          <InputComponent
            type={'password'}
            label={<FormattedMessage id="newPassword" />}
            inputValue={newPassword}
            inputCallback={changeNewPassword}
          />
        </div>
        <div className={s.loginContainer}>
          <InputComponent
            type={'password'}
            label={<FormattedMessage id="repeatPassword" />}
            inputValue={confirmPassword}
            inputCallback={changeConfirmPassword}
          />
        </div>
        <div className={s.buttonsContainer}>
          <Button
            disabled={isEmpty(login) || isEmpty(password) || isEmpty(newPassword) || isEmpty(confirmPassword)}
            type="primary"
            shape="round"
            size='large'
            onClick={() => {
            dispatch(changePassword({
              login: login,
              password: password,
              new_password: newPassword,
              new_password_repeat: confirmPassword,
              language: locale
            }))}
          }>
            <span className={s.buttonText}><FormattedMessage id="passwordRecovery" /></span>
          </Button>
        </div>

        <div className={s.buttonsContainer}>
          <Link to="/login/student"><FormattedMessage id="authorisationButton" /></Link>
        </div>

        { error && <div className = {s.errorContainer}>
          <ErrorMessage />
        </div>}
      </div>
    </div>
  );
};

export default compose(
    withRouter
)(ChangePassword);