import React from "react";
import { Modal, Icon, Input, Button } from "antd";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";

import { closePaymentModal } from "../../../actions/payment";
import s from "./PaymentModal.module.scss";

const closeIcon = <Icon type="close-circle" style={{ fontSize: "20px" }} />;

function copyText(text) {
  let textArea = document.createElement("textarea");
  textArea.width = "1px";
  textArea.height = "1px";
  textArea.background = "transparents";
  textArea.value = text;
  document.body.append(textArea);
  textArea.select();
  document.execCommand("copy");
  document.body.removeChild(textArea);
}

const PaymentModal = (props) => {
  const { isOpen, closePaymentModal, paymentId, payments } = props;

  let eripId;
  if (payments && paymentId) {
    eripId = (payments ? payments.find((p) => p.name === paymentId) : {})
      .erip_id;
  }

  return (
    <Modal
      visible={isOpen}
      className={s.modal}
      footer={null}
      closeIcon={closeIcon}
      onCancel={closePaymentModal}
    >
      <div className={s.title}>
        <FormattedMessage id="paymentReceiptGenerated" />
      </div>
      <div className={s.eripTitle}>
        <FormattedMessage id="yourPaymentERIPNumber" />
      </div>
      <div className={s.eripWrapper}>
        <Input className={s.eripInput} value={eripId} />
        <Button
          className={s.eripCopyBtn}
          icon="copy"
          onClick={() => copyText(eripId)}
        />
      </div>
      <div className={s.eripSubtitle}>
        <FormattedMessage id="paymentNumberIsValid24Hours" />
      </div>

      <a
        className={s.fileWrapper}
        href="https://www.bsmu.by/page/3/1634/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormattedMessage id="readInstructionsForPayment" />
      </a>
    </Modal>
  );
};

const mapDispatch = { closePaymentModal };

const mapState = (state) => ({
  isOpen: state.payment.isPaymentModalOpen,
  payments: state.payment.payments.data,
  paymentId: state.payment.paymentId,
});

export default compose(
  connect(mapState, mapDispatch),
  injectIntl
)(PaymentModal);
