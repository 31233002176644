import React from "react";
import { Modal, Icon } from "antd";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { toggleModal, sendFeedbackRequest } from "../../actions/feedbackModal";
import { loginProfileSelector } from "../../selectors/userSelectors";

import FeedbackForm from "../Forms/Feedback/Feedback";

import styles from "./FeedbackModal.module.scss";

const closeIcon = <Icon type="close-circle" style={{ fontSize: "20px" }} />;

const FeedbackModal = ({
  isOpen,
  toggleModal,
  sendFeedback,
  intl,
  isLoading,
  login
}) => (
  <Modal
    visible={isOpen}
    className={styles.modal}
    footer={null}
    closeIcon={closeIcon}
    onCancel={toggleModal}
  >
    {/* To reset form after closing */}
    {isOpen && (
      <FeedbackForm
        onlyFeedback={login}
        onSubmit={(values) => {
          sendFeedback({
            values,
            constants: {
              error: intl.formatMessage({ id: "error.failToSendFeedback" }),
              success: intl.formatMessage({ id: "success.feedbackSent" }),
            },
          });
        }}
        isLoading={isLoading}
      />
    )}
  </Modal>
);

const mapDispatch = { toggleModal, sendFeedback: sendFeedbackRequest };

const mapState = (state) => ({
  isLoading: state.feedbackModal.isLoading,
  login: loginProfileSelector(state),
});

export default compose(
  connect(mapState, mapDispatch),
  injectIntl
)(FeedbackModal);
