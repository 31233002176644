import React from 'react';
import { compose } from "redux";
import { isEmpty } from 'lodash';
import { withRouter } from "react-router-dom";
import { Button, Switch } from 'antd';
import InputComponent from '../../components/InputComponent/InputComponent';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { IntlContext } from "../../i18n/IntlContext";
import { FormattedMessage } from "react-intl";
import s from './authorisation.module.scss'
import './switch.scss'
import {Link} from "react-router-dom";

const Authorisation = ({ title, loginOnChange, passwordOnChange, login, loginText, passwordText, error, localisationMode, changeLocalisationMode, ...props }) => {
  const { switchToEnglish, switchToRussian } = React.useContext(IntlContext);

  const changeLogin = e => loginOnChange(e.currentTarget.value);
  const changePassword = e => passwordOnChange(e.currentTarget.value);

  const changeLanguage = (checked) => {
    changeLocalisationMode(checked);
    switch (checked) {
      case false: switchToEnglish(); break;
      case true: switchToRussian(); break;
      default: switchToRussian(); break;
    }
  }

  const loginLabel = <FormattedMessage id="insertLogin" />
  const passwordLabel = <FormattedMessage id="insertPassword" />

  return (
    <div className={s.wrapper}>
      <span className={s.title}>
        {title}
      </span>
      <div className={s.content}>
        <div className={s.loginContainer}>
          <InputComponent label={loginLabel}
            placeholder={'Diego Sanchez'}
            inputCallback={changeLogin}
            inputValue={loginText} />
        </div>
        <InputComponent type={'password'}
          label={passwordLabel}
          inputValue={passwordText}
          inputCallback={changePassword}
        />
        {props.location.pathname === '/login/student' && <div className={s.switchContainer} >
          <span className={s.en}>En</span>
          <Switch checked={localisationMode} onClick={e => changeLanguage(e)} />
          <span className={s.ru}>Ru</span>
        </div>}
        <div className={s.buttonsContainer}>
          <Button disabled={isEmpty(loginText) || isEmpty(passwordText)} type="primary" shape="round" size='large' onClick={() => {
            const key = props.location.pathname.split('/');
            return login({ pathname: key[2], login: loginText, password: passwordText, localisationMode: localisationMode })
          }
          }>
            <span className={s.buttonText}><FormattedMessage id="authorisationButton" /></span>
          </Button>
        </div>
        {error && <div className={s.errorContainer}>
          <ErrorMessage />
        </div>}
        {props.location.pathname === '/login/student' && (
          <div className={s.feedbackBtn} onClick={props.toggleModal}><FormattedMessage id="contactSupport" /></div>
        )}
        {props.location.pathname === '/login/student' && (
          <Link className={s.feedbackBtn} to='/password-recovery'><FormattedMessage id="restorePassword" /></Link>
        )}
        {props.location.pathname === '/login/student' && (
          <Link className={s.feedbackBtn} to='/change-password'><FormattedMessage id="changePassword" /></Link>
        )}
      </div>
    </div>
  );
};

export default compose(
  withRouter,
)(Authorisation);
