import React from 'react';
import { connect } from "react-redux";
import { Button } from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { FormattedMessage } from "react-intl";
import 'moment/locale/ru';

import { messagesSelector } from '../../selectors/mailSelectors';
import { localisationModeSelector } from '../../selectors/userSelectors';
import history from '../../history';
import s from './messagesList.module.scss';

const MessagesList = ({ messages, getMessages, hasMore, localisationMode }) => {
  localisationMode ? moment.locale('ru') : moment.locale('en');
  const unreadMessage = `${s.message} ${s.unreadMessage}`;
  return (
    <div className={s.messagesList}>
      {
        messages.length === 0 && <div className = {s.messagesNotFoundWrapper}>
          <div className = {s.messagesNotFound}>
            <FormattedMessage id="messagesNotFound" />
          </div>
        </div>
      }
      {
        messages && messages.map(message => (
          <div className={ message.students[0].is_read ? s.message : unreadMessage } key = {message.id} onClick = { () => history.push(`/messages/${message.id}`) }>
            <div className={s.messageHeader}>
              <div className={s.title}>{message.title}</div>
              <div className={s.date}>{moment(message.created_at).format('DD MMMM YYYY')}</div>
            </div>
            <div className={s.messageText}>
              {message.message}
            </div>
          </div>
        ))
      }
      { !isEmpty(messages) && hasMore && <Button className = { s.loadMore } onClick = {() => getMessages({ next: true }) } ><FormattedMessage id="loadingMore" /></Button> }
    </div>
  );
};

const mapStateToProps = state => {
	return {
    localisationMode: localisationModeSelector(state),
		messages: messagesSelector(state),
	}
};

export default connect(mapStateToProps, null)(MessagesList);
