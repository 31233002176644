import { combineReducers, createStore, applyMiddleware } from 'redux';
import userReduser from './userReduser/userReduser';
import createSagaMiddleware from 'redux-saga';
import { watcherSaga } from '../sagas/watcherSaga';
import { composeWithDevTools } from 'redux-devtools-extension';
import mailReducer from './mailReducers/mailReducer';
import feedbackModalReducer from './feedbackModalReducer/feedbackReduser';
import mainReduser from './reduser';
import paymentReduser from './payment/payment';

const rootReducer = combineReducers({
	root: mainReduser,
	user: userReduser,
	mail: mailReducer,
	feedbackModal: feedbackModalReducer,
	payment: paymentReduser,
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
	rootReducer,
	composeWithDevTools(
		applyMiddleware(sagaMiddleware),
	)

);

sagaMiddleware.run(watcherSaga);

export default store;
