import axios from "axios";

const baseURL = "https://bsmu.alphabyte.pro/";

export default axios.create({
  baseURL,
});

export const buildUrlParams = (params) => {
  const result = Object.keys(params).reduce((acc, key) => {
    const param = params[key];
    return acc + (param ? `&${key}=${param}` : "");
  }, "");
  return result.replace(/^&/, "");
};
