import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { get, first, isEqual, last, isArray } from "lodash";
import { Row, Col } from 'antd';
import axios from '../../api';

import { ROUTES, ICONS } from "../../constants";
import { fetchSchedule, setSchedule } from "../../actions";
import { scheduleSelector } from "../../selectors";
import { getProfile, loginRowguid } from "../../actions/authorisation";
import {
  groupSelector,
  studentFacultySelector
} from "../../selectors/userSelectors";
import s from "./schedule.module.scss";
import Header from "../../components/Headers/MessagesHeader";
import headerStyles from '../../components/Headers/header.module.scss';
import { authorisationHoc } from '../../hoc/authorusationHoc';

class Schedule extends React.Component {
  state = {
    linkToJournal: ''
  };

  componentDidMount() {
    if (this.props.user.profile) {
      const language = this.props.user.localisationMode ? "ru" : "en";
      this.props.fetchSchedule({ language });
    } else {
      const key = this.props.location.pathname.split('/')[1];
      this.props.getProfile({
        token: localStorage.getItem(ROUTES[key]),
        pathname: key,
        redirect: this.props.location.pathname
      });
    }
    
    this.getLink();
  }

  componentWillReceiveProps({ user: { profile } }) {
    if (!isEqual(profile, this.props.user.profile) && profile) {
      const language = this.props.user.localisationMode ? "ru" : "en";
      this.props.fetchSchedule({ language });
    }
  }

  getLink = () => {
    axios.get(`/api/journal`).then((response) => {
       this.setState({ linkToJournal: response.data.url });
     });
   };

  renderTiming = (text) => {
    const items = text.split(':');
    return (
      <>
        <div className={s.itemTitle}>{items[0] + ':'}</div>
        <div className={s.itemText}>{items[1]}</div>
      </>
    );
  }

  renderInfoItem = (item, index) => {
    if (Object.keys(item).includes('file')) {
      return <div className={s.downloadExams} key={index}>
        <div className={s.downloadTitle}>
          <a
            className={s.downloadText}
            href={item.file}
          >
            Скачать
          </a>
          <span className={s.infoText}>{` ${item.text}`}</span>
        </div>
        <a
          className={s.downloadText}
          href={item.file}
        >
          <div className={s.downloadIcon}>
            <img src={ICONS[last(item.file.split('.'))]} className={s.docFile} alt='doc' />
            <p className={s.filename}>
              {last(item.file.split('/'))}
            </p>
          </div>
        </a>
      </div>
    } else {
      return <div className={s.downloadExams} key={index}>
        <div className={s.downloadTitle}>
          <a
            className={s.downloadText}
            href={item.link}
          >
            Ссылка на
          </a>
          <span className={s.infoText}>{` ${item.text}`}</span>
        </div>
        <a
          className={s.linkText}
          href={item.link}
        >
          {item.link}
        </a>
      </div>
    }
  }

  render() {
    const { schedule } = this.props;
    let infoItems = [];
    if (isArray(get(schedule, "links.exams", []))) {
      infoItems = get(schedule, "links.exams", []).concat(get(schedule, "links.more", []));
    } else {
      infoItems.push(get(schedule, "links.exams", {}));
      infoItems = infoItems.concat(get(schedule, "links.more", []));
    }

    return (
      <div className={s.wrapper}>
        <div className={s.content}>
          <Header linkToJournal={this.state.linkToJournal} headWrapper={headerStyles.wrapper2} />
          <div className={s.head}>
            <div className={s.title}>{`${this.props.faculty}`}</div>
          </div>
          <div className={s.container}>
            <Row className={s.screenRow}>
              <Col xs={{span: 24}} lg={{span: 12}}>
                <div className={s.studentInfo}>
                  <div className={s.course}>
                    <div className={s.itemTitle}>Курс:</div>
                    <div className={s.itemText}>{get(first(get(schedule, "links.timetable", [])), "text", "")}</div>
                  </div>
                  <div className={s.group}>
                    <div className={s.itemTitle}>Группа:</div>
                    <div className={s.itemText}>{this.props.group}</div>
                  </div>
                </div>
                <div className={s.timing}>
                  {get(schedule, "links.timing", []).map((item, index) => (
                    <div className={s.dates} key={index}>
                      {this.renderTiming(item.text)}
                    </div>
                  ))}
                </div>
                <div className={s.other}>
                  {get(schedule, "links.other", []).map((item, index) => (
                    <div className={s.otherText} key={index}>
                      {item.text}
                    </div>
                  ))}
                </div>
              </Col>
              <Col xs={{span: 24}} md={{span: 12}} className={s.infoItems}>
                <div className={s.downloadPart}>
                  {infoItems.map((item, index) => this.renderInfoItem(item, index))}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    group: groupSelector(state),
    faculty: studentFacultySelector(state),
    schedule: scheduleSelector(state)
  };
};

const mapDispatchToProps = {
  getProfile,
  fetchSchedule,
  setSchedule,
  loginRowguid
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(authorisationHoc(Schedule));
