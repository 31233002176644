import { createSelector } from "reselect";

const userSelector = state => state.user;

export const loginSelector = createSelector(userSelector, (user) => user.login || '');
export const loginProfileSelector = createSelector(userSelector, (user) => user && user.profile ? (user.profile.login || '') : '');
export const passwordSelector = createSelector(userSelector, (user) => user.password || '');
export const errorSelector = createSelector(userSelector, (user) => user.error || '');

export const firstNameSelector = createSelector(userSelector, (user) => user.profile && (user.profile.first_name || ''));
export const lastNameSelector = createSelector(userSelector, (user) => user.profile &&  (user.profile.last_name || ''));
export const groupSelector = createSelector(userSelector, (user) => user.profile &&  (user.profile.group.name || ''));
export const courseSelector = createSelector(userSelector, (user) => user.profile &&  (user.profile.group.course || ''));
export const studentFacultySelector = createSelector(userSelector, (user) => user.profile &&  (user.profile.faculty.name || ''));
export const localisationModeSelector = createSelector(userSelector, (user) => user.localisationMode);
