import React from "react";
import { Row, Col } from "antd";
import { FormattedMessage } from "react-intl";

import s from "./MobilePaymentCard.module.scss";

const MobilePaymentCard = (props) => {
  const { onPayClick, payment } = props;

  return (
    <div className={s.card}>
      <div className={s.title}>{payment.name}</div>

      <div className={s.cardContent}>
        <Row type="flex">
          <Col span={12}>
            <FormattedMessage id="serviceCost" />
          </Col>
          <Col span={12} type="flex" align="end">
            {payment.serviceCost}
          </Col>
        </Row>

        <Row type="flex">
          <Col span={12}>
            <FormattedMessage id="amountOfInterest" />
          </Col>
          <Col span={12} type="flex" align="end">
            {payment.amountOfInterest}
          </Col>
        </Row>

        <Row type="flex">
          <Col span={12}>
            <FormattedMessage id="expiredDays" />
          </Col>
          <Col span={12} type="flex" align="end">
            {payment.expiredDays}
          </Col>
        </Row>

        <Row type="flex">
          <Col span={12}>
            <FormattedMessage id="totalPayable" />
          </Col>
          <Col span={12} type="flex" align="end">
            {payment.totalPayable}
          </Col>
        </Row>

        <Row type="flex">
          <Col span={12}>
            <FormattedMessage id="paymentCrediting" />
          </Col>
          <Col span={12} type="flex" align="end">
            {payment.paymentCrediting}
          </Col>
        </Row>

        <Row type="flex">
          <Col type="flex" align="end" span={24}>
            {payment.nPayment ? (
              payment.nPayment
            ) : (
              <button className={s.payBtn} onClick={onPayClick}>
                Оплатить
              </button>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MobilePaymentCard;
